<script setup lang="ts">
import { AUTH, PROMO_WHEEL_SPIN } from '~/constants';
import { useGame } from '~/composables/chip-promo';

const { isLoggedIn }: any = inject(AUTH);
const tempUserId = ref<number | null>(null);
const email = ref<string | null>(null);
const verificationCode = ref<string | null>(null);
const sequence = ref<number>(0);
const isVerified = ref<boolean>(!!isLoggedIn.value || false);
const isAvailable = ref<boolean>(!!isLoggedIn.value || false);
const showFreeSpin = ref<boolean>(true);
const showRegisterAddress = ref<boolean>(false);
const agreeTos = ref<boolean>(false);
const spinResult = ref<number>(0);
const degreeVal = ref<number>(25);

// Game data
const wheelSpinData = useGame('WheelSpin');
const wheelSpin = computed(() => wheelSpinData || null)

provide(PROMO_WHEEL_SPIN, {
  tempUserId,
  email,
  verificationCode,
  sequence,
  isVerified,
  isAvailable,
  showFreeSpin,
  showRegisterAddress,
  spinResult,
  agreeTos,
  degreeVal,
  wheelSpin,
})
</script>

<template>
  <slot />
</template>
