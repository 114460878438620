export default defineComponent({
  name: 'HeaderContainer',
  setup (props, { slots }): { slots: any } {
    return {
      slots,
    };
  },
  render () {
    const { slots } = this;
    return (
      <header class="w-full p-0 m-0">
        <nav
          class="
            w-full h-16
            flex justify-start md:justify-end
            px-3 py-2
            text-paragraph
          "
        >
          {slots && slots?.default()}
        </nav>
      </header>
    );
  },
});
