<template>
  <p
    class="
      text-base md:text-[23px] font-extrabold
      leading-6 md:leading-[150%] md:pt-1
      tracking-primary md:tracking-primaryMd
    "
  >
    <slot />
  </p>
</template>
