<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: any;
    maska?: any;
  }>(),
  { modelValue: null, maska: null },
);
const emit =
  defineEmits<{
    (e: 'update:modelValue', value: any): void;
    (e: 'keypress', value: any): void;
  }>();

const modelVal = reactive({
  input: props.modelValue,
});

const handleChange = () => {
  emit('update:modelValue', modelVal.input);
};

const handleKeyup = () => {
  emit('keypress', modelVal.input);
};

watch(
  () => props.modelValue, (v) => {
    modelVal.input = v;
  });
</script>
<template>
  <input
    v-model="modelVal.input"
    v-maska
    class="
      border-0 outline-none focus:outline-none
      ring-0 active:ring-0 hover:ring-0 focus:ring-0
      shadow-none bg-transparent
      disabled:cursor-not-allowed
      disabled:opacity-75
      text-sm md:text-[15.508px]
      leading-[14.7px] md:leading-[16.283px]
      w-full h-fit min-h-hit
      placeholder-paragraph
    "
    :data-maska="maska"
    @input="handleChange"
    @keydown="handleKeyup"
  >
</template>
