<template>
  <div class="text-center text-white font-bold">
    <div class="leading-[37.8px] md:leading-[28.35px] text-4xl md:text-xxl">
      <slot name="value" />
    </div>
    <div
      class="
        leading-[12.6px] md:leading-[11.117px]
        text-xs md:text-[10.588px]
        tracking-[0.72px] md:tracking-[0.635px]
        uppercase
      "
    >
      <slot name="unit" />
    </div>
  </div>
</template>
