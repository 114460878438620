<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: boolean;
  }>(),
  { modelValue: false },
);
const emit =
  defineEmits<{
    (e: 'update:modelValue', value: boolean): void;
  }>();

const handleToggleCheck = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>
<template>
  <label
    class="
      flex justify-start items-center
      select-none
      cursor-pointer
      hover:border-primary
      gap-2
    "
    @click="handleToggleCheck"
  >
    <slot name="checkbox" />
    <slot />
  </label>
</template>
