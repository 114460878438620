<script lang="ts" setup>
import { useTimeoutPoll } from "@vueuse/core";
import HorizontalBar from "~/components/pages/chip-promo/atoms/HorizontalBar.vue";
import CountdownItem from "~/components/pages/chip-promo/atoms/CountdownItem.vue";
import MiddleText from "~/components/pages/chip-promo/atoms/MiddleText.vue";
import { useCountdown, useTwoNumber } from "~/composables/base";
import type { SpinData } from "~/types/chip-promo/landing-page";
import { WALLET_ICON } from "~/icons/icons";
import { SHARED_DATA } from "~/constants";

const { spinData } = defineProps<{
  spinData: SpinData;
}>();

const { usersCount, wageredAmount }: any = inject(SHARED_DATA);

async function timeDown() {
  if (spinData.countdown > 1000) {
    spinData.countdown -= 1000;
  } else {
    spinData.countdown = 0;
  }
}

const { resume } = useTimeoutPoll(timeDown, 1000);

const remainingTime = computed(() => spinData.countdown - spinData.current);
const remaining = computed(() => useCountdown(remainingTime.value));

onMounted(async () => {
  resume();
});
</script>

<template>
  <div
    class="inline-flex justify-center items-center flex-wrap lg:flex-nowrap lg:bg-[#ACCCAC1A] lg:backdrop-blur-sm lg:border lg:border-green-600 lg:rounded-[14px] w-full lg:h-[86px] relative text-center px-3 md:px-10 lg:px-6 py-3 gap-5 lg:gap-1"
  >
    <MiddleText
      class="uppercase text-green-600 text-xl w-full lg:w-1/6 text-center"
    >
      WE ARE ALMOST THERE
    </MiddleText>

    <HorizontalBar class="hidden lg:block max-h-1" />

    <div class="w-full inline-flex justify-between items-center lg:w-4/6">
      <CountdownItem class="w-1/6">
        <template #value>
          {{ remainingTime > 0 ? useTwoNumber(remaining.days) : "--" }}
        </template>
        <template #unit> days</template>
      </CountdownItem>

      <HorizontalBar class="max-h-1" />

      <CountdownItem class="w-1/6">
        <template #value>
          {{ remainingTime > 0 ? useTwoNumber(remaining.hours) : "--" }}
        </template>
        <template #unit> hours</template>
      </CountdownItem>

      <HorizontalBar class="max-h-1" />

      <CountdownItem class="w-1/6">
        <template #value>
          {{ remainingTime > 0 ? useTwoNumber(remaining.minutes) : "--" }}
        </template>
        <template #unit> minutes</template>
      </CountdownItem>

      <HorizontalBar class="max-h-1" />

      <CountdownItem class="w-1/6">
        <template #value>
          {{ remainingTime > 0 ? useTwoNumber(remaining.secs) : "--" }}
        </template>
        <template #unit> seconds</template>
      </CountdownItem>
    </div>

    <HorizontalBar class="hidden lg:block max-h-1" />

    <div class="w-full lg:w-1/6">
      <MiddleText
        class="text-green-600 text-2xl lg:!text-xxl leading-[25.2px] lg:leading-[28.35px]"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(wageredAmount)
        }}
      </MiddleText>
      <div
        class="uppercase font-extrabold text-[10px] leading-[10.5px] tracking-[0.6px] lg:text-[10.588px] lg:leading-[11.117px] lg:tracking-[0.635px] flex justify-center items-center gap-2"
      >
        <svg class="text-green-600 h-2 w-2">
          <use :xlink:href="WALLET_ICON" />
        </svg>

        Total Prizes Won
      </div>
    </div>
  </div>
</template>
