<template>
  <div
    class="
      w-[271px] h-[164px] md:w-[326px] md:h-[192px]
      absolute top-[11.25px] left-[15px] md:top-[10px] md:left-[20px]
      p-0
      z-101
      opacity-25
    "
  >
    <svg
      class="md:hidden"
      fill="none"
      viewBox="0 0 271 164"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style="mix-blend-mode: multiply;">
        <path
          d="M147.607 23.4571L156.091 1.70008C149.347 0.82664 142.475 0.37561 135.5 0.37561C128.525 0.37561 121.651 0.826641 114.908 1.70036L123.391 23.4571C54.6952 29.7186 0.839844 88.7199 0.839844 160.595C0.839844 161.705 0.855214 162.812 0.88096 163.916C2.52766 92.6828 57.0745 34.8444 125.898 29.8858L135.497 54.5052V54.5133L135.499 54.5091L135.5 54.5133V54.5052L145.1 29.8858C213.922 34.8444 268.467 92.68 270.117 163.911C270.143 162.808 270.158 161.703 270.158 160.595C270.158 88.7202 216.303 29.7186 147.607 23.4571Z"
          fill="#22974A"
        />
      </g>
    </svg>

    <svg
      class="hidden md:block"
      fill="none"
      viewBox="0 0 326 192"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style="mix-blend-mode: multiply;">
        <path
          d="M177.607 27.7986L187.842 2.454C179.707 1.43653 171.415 0.911133 163 0.911133C154.585 0.911133 146.292 1.43654 138.156 2.45432L148.391 27.7986C65.5121 35.0925 0.537598 103.823 0.537598 187.549C0.537598 188.843 0.556142 190.132 0.587203 191.419C2.57388 108.439 68.3826 41.0636 151.415 35.2874L162.997 63.9664V63.9758L162.999 63.9709L163.001 63.9758V63.9664L174.582 35.2874C257.613 41.0636 323.421 108.436 325.411 191.412C325.442 190.128 325.46 188.841 325.46 187.549C325.46 103.823 260.486 35.0925 177.607 27.7986Z"
          fill="#22974A"
        />
      </g>
    </svg>
  </div>
</template>
