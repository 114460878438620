export default function() {
  const contentClasses: {
    wrapper: string[],
    description: string[]
  } = {
    wrapper: [
      'w-full',
      'max-w-container',
      'md:max-w-screen-lg',
      'lg:max-w-containerLXl',
      'mx-auto',
      'relative',
    ],
    description: [
      'text-base',
      'md:text-2xl',
      'lg:text-xxxl',
      'font-bold',
    ],
  };

  return {
    contentClasses,
  };
}
