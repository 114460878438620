<script lang="ts" setup>
import type { Bet } from '~/types/bets';
import MiniSpinner from '~/components/pages/chip-promo/organisms/Tooltip/MiniSpinner.vue';
import JButton from '~/atoms/JButton.vue';

const { betData } = defineProps<{
  betData: Bet;
}>();
const emits = defineEmits(['fairness-data']);

const openFairnessModal = () => {
  emits('fairness-data', betData);
};
</script>
<template>
  <div
    class="flex justify-between gap-2 rounded-md w-full py-3.5 text-sm text-paragraph z-10 backdrop-blur-sm md:backdrop-blur-none"
  >
    <div class="w-7/12">
      <div>
        <div class="spin-results-content">
          <b>Spin Results</b>
        </div>
        <div class="mt-2">
          <label for=""> Server Seed: </label>
          <input
            :value="betData.server_seed_unhashed"
            class="seed-input"
            disabled
            readonly
            type="text"
          />
        </div>
        <div class="mt-2">
          <label for=""> Client Seed: </label>
          <input
            :value="betData.client_seed"
            class="seed-input"
            disabled
            readonly
            type="text"
          />
        </div>
        <div class="mt-2">
          <div class="flex rounded-md w-full">
            <div class="w-12/12 text-left">
              <JButton
                v-close-popper
                :class="
                  cn([
                    'py-[6px]',
                    'px-[12px]',
                    'sm:px-[10px]',
                    'sm:py-[10px]',
                    'sm:w-[92px]',
                    'sm:h-[40px]',
                    'text-paragraph',
                  ])
                "
                size="sm"
                variant="alternative"
                @click="openFairnessModal"
              >
                Verify Me
              </JButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-5/12 text-right">
      <MiniSpinner :bet-data="betData" class="mini-spinner w-100" />
      <div class="text-center payout">${{ betData.payout }}</div>
    </div>
  </div>
</template>
<style scoped>
.seed-input {
  color: rgb(86, 153, 86);
  background: #1c4b1b8b;
  border-radius: 4px;
}

.spin-results-content {
  font-size: 1.2em;
}

.payout {
  font-size: 2em;
  font-weight: bold;
}
</style>
