<script lang="ts" setup>
import MiddleText from '~/components/pages/chip-promo/atoms/MiddleText.vue';
import NormalText from '~/components/pages/chip-promo/atoms/NormalText.vue';
import useSimpleSlider from '~/composables/useSimpleSlider';
import type { SlideContent } from '~/types';

const { contents, sliderState, sliderConfig } = useSimpleSlider();

const breadcrumbContents: SlideContent[] = [
  {
    title: 'SPIN & WIN UP TO $500',
    subTitle: 'Register for a free spin worth up to $500 - Every spin is a winner!',
    widthClass: 'w-[200px] md:w-[250px]',
    wrapperClass: 'flex-shrink-0',
    gap: false,
  },
  {
    title: 'FOUND A JUNGLEBET CHIP IRL?',
    subTitle: 'Find a physical Junglebet chip on earth and enjoy 10 free spins, just scan QR code!',
    widthClass: 'w-[250px] md:w-[350px] xl:w-full',
    wrapperClass: 'flex-shrink-0',
    gap: true,
  },
  {
    title: 'REAL WINNING PRIZES',
    subTitle: 'All prizes credited directly to your account to be used upon launch.',
    widthClass: '',
    wrapperClass: 'flex-shrink-0',
    gap: true,
  },
];

contents.value.push(...breadcrumbContents);
sliderConfig.autoplay = true;
sliderConfig.delay = 3000;
</script>

<template>
  <div class="w-full">
    <div
      class="
        hidden w-0 opacity-0 lg:opacity-100
        lg:w-full max-w-[300px] lg:px-3
        lg:flex lg:flex-col lg:justify-stretch lg:items-center
        lg:gap-y-12
      "
    >
      <template
        v-for="(cont, c) in contents"
        :key="c"
      >
        <JbGradientLine
          v-if="cont.gap"
          class="w-[265px] h-[2px]"
        />

        <div
          :class="[cont.wrapperClass]"
        >
          <MiddleText
            :class="[cont.widthClass]"
            class="text-center text-green-600 text-base"
          >
            {{ cont.title }}
          </MiddleText>

          <NormalText
            class="mt-1 text-center text-paragraph leading-[110%]"
          >
            {{ cont.subTitle }}
          </NormalText>
        </div>
      </template>
    </div>
    <div
      class="
        lg:hidden w-full overflow-hidden
        p-2 relative
        flex justify-center items-center flex-wrap
        min-h-[104px]
      "
    >
      <template
        v-for="(cont, c) in contents"
        :key="c"
      >
        <div
          v-show="sliderState.currentIndex === c"
          :class="[
            cont.wrapperClass
          ]"
          class="max-w-[350px] h-[90px] min-h-[80px]"
        >
          <div class="w-full flex justify-center animate-slide-in-right duration-300">
            <MiddleText
              :class="[cont.widthClass]"
              class="text-center text-green-600 text-base"
            >
              {{ cont.title }}
            </MiddleText>
          </div>

          <NormalText
            class="mt-1 text-center text-paragraph animate-slide-in-right duration-300 leading-none"
          >
            {{ cont.subTitle }}
          </NormalText>
        </div>
      </template>

      <div
        class="flex flex-row gap-x-3 w-full justify-center items-center"
      >
        <template
          v-for="(content, i) in contents"
          :key="i"
        >
          <div
            :class="[
              sliderState.currentIndex === i ? 'w-[50.754px]' : 'w-4'
            ]"
            class="h-2 rounded-[25.377px] bg-green-950 flex items-center"
          >
            <div
              v-if="sliderState.currentIndex === i"
              class="w-4 h-2 rounded-[25.377px] bg-green-600 animate-slide-in-left-slow duration-[5000ms]"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
