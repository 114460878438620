<script lang="ts" setup>
const { modalVisible } = defineProps<{
  modalVisible: boolean;
}>();

const emits = defineEmits<{
  (e: 'close', payload: boolean): void;
}>();

const handleModalClose = () => {
  emits('close', false);
};

const odds = [
  { value: '$1', odd: '1 in 1.66' },
  { value: '$2', odd: '1 in 4' },
  { value: '$5', odd: '1 in 25' },
  { value: '$10', odd: '1 in 100' },
  { value: '$20', odd: '1 in 500' },
  { value: '$100', odd: '1 in 5,000' },
  { value: '$500', odd: '1 in 7,777' },
];
</script>

<template>
  <JbSimpleModal
    :is-open="modalVisible"
    :no-header="false"
    class="inline-flex justify-center items-start md:items-center"
    class-name="w-[180px] !min-w-[200px] h-full bg-chip my-8 md:my-0 px-[26px] py-[26px]"
    @close:simple-modal="handleModalClose"
  >
    <template #header>
      <div class="font-bold flex gap-x-[10px] md:gap-x-[25px]">
        Odds
      </div>
    </template>

    <template
      v-for="(o, i) in odds"
      :key="i"
    >
      <div
        class="grid grid-cols-2 font-bold text-sm border-b-[1px] border-b-solid border-b-line py-2"
      >
        <div class="text-left">
          {{ o.value }}
        </div>
        <div class="text-right text-paragraph">
          {{ o.odd }}
        </div>
      </div>
    </template>
  </JbSimpleModal>
</template>
