<script lang="ts" setup>
import usePromoHomeClasses from '~/composables/chip-promo/usePromoHomeClasses';

const { mainLogo } = usePromoHomeClasses();
</script>

<template>
  <div
    class="
      w-full mt-2 lg:mt-0
      flex justify-center items-center
    "
  >
    <NuxtLink to="/">
      <JbLogo
        :class="[
          ...mainLogo
        ]"
      />
    </NuxtLink>
  </div>
</template>
