<script setup lang="ts">
const props = withDefaults(defineProps<{
  val: number;
  max: number;
  color: string;
  border: string;
}>(), {
  max: 1,
  val: 0,
  color: 'bg-transparent',
  border: 'border-paragraph',
});

const width = computed(() => (props.val / props.max) * 100);
</script>

<template>
  <div
    class="flex h-2 border rounded"
    :class="[border]"
  >
    <div
      :style="{ width: `${width}%` }"
      :class="[color]"
      class="progress-bar flex h-full"
    />
  </div>
</template>

<style scoped>
.progress-bar {
  transition: 0.5s ease-in-out;
}
</style>
