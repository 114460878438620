import { generateBinarySeed, verifyGame } from '~/utils';

export const useVerifyFairness = (props: {
  serverSeed: Ref<string>;
  clientSeed: Ref<string>;
  nonce: Ref<number>;
  range?: [number, number];
  count: Ref<number>;
  intOnly?: boolean;
}): {
  loading: Ref<boolean>;
  result: Ref<number[]>;
  first: ComputedRef<number>;
  canVerify: ComputedRef<boolean>;
  verified: Ref<boolean>;
} => {
  const {
    serverSeed,
    clientSeed,
    nonce,
    range = [0, 1],
    count,
    intOnly = false,
  } = props;
  const loading = ref(false);
  const result = ref<number[]>([]);

  const canVerify = computed<boolean>(
    () => !!(serverSeed.value && clientSeed.value && count.value > 0),
  );
  const verified = ref(false);

  const verify = async () => {
    try {
      loading.value = true;
      const newResult = await verifyGame.generateFloats({
        count: count.value,
        range,
        clientSeed: generateBinarySeed(clientSeed.value),
        serverSeed: generateBinarySeed(serverSeed.value),
        nonce: nonce.value,
        intOnly,
      });

      if (canVerify.value) {
        result.value = newResult;
        verified.value = true;
      }
    } catch (e) {
      result.value = [];
    } finally {
      loading.value = false;
    }
  };

  const verifyDfn = useDebounceFn(() => verify(), 1000);

  watchEffect(async () => {
    if (serverSeed.value && clientSeed.value && count.value > 0) {
      loading.value = true;
      if (nonce.value) {
        // for only track nonce
      }
      verified.value = false;
      await verifyDfn();
    } else {
      verified.value = false;
    }
  });

  return {
    loading,
    result,
    first: computed(() => result.value?.[0] || 0),
    canVerify,
    verified,
  };
};
