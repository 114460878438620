<script lang="ts" setup>
import type { Bet } from '~/types/bets';
import FairnessForm from '~/components/pages/chip-promo/organisms/Tooltip/FairnessForm.vue';

const { modalVisible, betData } = defineProps<{
  modalVisible: boolean;
  betData: Bet;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const handleModalClose = () => {
  emits('close');
};
</script>
<template>
  <JbSimpleModal
    :is-open="modalVisible"
    :no-header="false"
    class="inline-flex justify-center items-start md:items-center"
    class-name="w-[480px] h-full bg-chip mt-12 md:mt-0 px-[26px] py-[26px]"
    @close:simple-modal="handleModalClose"
  >
    <template #header>
      <div class="text-[25px] font-bold">Verify Fairness</div>
    </template>

    <FairnessForm :bet-data="betData" />
  </JbSimpleModal>
</template>
