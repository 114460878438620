<script lang="ts" setup>
import LazySectionContainer from '~/atoms/layouts/SectionContainer';
import LazySectionWrapper from '~/atoms/layouts/SectionWrapper';
import { cn } from '~/utils';
import PromoPageHeader from '~/components/pages/chip-promo/organisms/PromoPageHeader.vue';
import LazyLandingPageLogo from '~/components/pages/chip-promo/molecules/LandingPageLogo.vue';
import LazyLandingPageStageContainer from '~/components/pages/chip-promo/organisms/LandingPageStageContainer.vue';
import Icons from '~/icons/Icons.vue';
import LazyFloatingBar from '~/components/organisms/FloatingBar.vue';
import StepProvider from '~/services/providers/chip-promo/StepProvider.vue';

useSeoMeta({
  title: 'Best Online Crypto Casino and Bitcoin Sports Betting',
});

onMounted(() => {
  window.addEventListener('popstate', () => {
    window.location.reload();
  });
});
</script>
<template>
  <StepProvider>
    <DelayHydration>
      <LazySectionContainer>
        <PromoPageHeader />

        <LazySectionWrapper>
          <DelayHydration>
            <LazyLandingPageLogo />
          </DelayHydration>

          <DelayHydration>
            <LazyLandingPageStageContainer />
          </DelayHydration>
        </LazySectionWrapper>

        <DelayHydration>
          <LazyFloatingBar />
        </DelayHydration>
      </LazySectionContainer>

      <Icons :class="cn('absolute bottom-0 left-0')" />
    </DelayHydration>
  </StepProvider>
</template>
