<template>
  <svg
    fill="none"
    viewBox="0 0 50 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9969 63.9089L24.9988 63.9039L25.0007 63.9089V63.8992L49.9144 2.38682C41.7552 1.36935 33.44 0.843994 25.0002 0.843994C16.5604 0.843994 8.24338 1.36935 0.0833435 2.38714L24.9969 63.8992V63.9089Z"
      fill="url(#paint0_linear_1_21402)"
    />
    <path
      d="M24.998 43.5346L24.9991 43.5319L25.0001 43.5346V43.5293L38.6888 9.73173C34.2057 9.17272 29.637 8.88403 24.9998 8.88403C20.3626 8.88403 15.7929 9.17272 11.3094 9.73192L24.998 43.5293V43.5346Z"
      fill="#ECF0EC"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_21402"
        gradientUnits="userSpaceOnUse"
        x1="24.9988"
        x2="24.9988"
        y1="0.843994"
        y2="63.9089"
      >
        <stop
          offset="0"
          stop-color="#28A553"
        />
        <stop
          offset="1"
          stop-color="#107031"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
