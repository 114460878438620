import type { SpinOption } from "~/types/chip-promo/landing-page";

export const SPIN_RESULT: number[] = [1, 2, 5, 10, 20, 100, 500];

export const FREE_SPIN_OPTIONS: SpinOption[] = [
  {
    label: "$1",
    value: 1,
    colors: "linear-gradient(330deg, #F5AC33 -2.54%, #F57000 86.22%)",
  },
  {
    label: "$2",
    value: 2,
    colors: "linear-gradient(90deg, #E30C40 0%, #FD1D1D 50%, #FC7145 100%)",
  },
  {
    label: "$5",
    value: 5,
    colors: "linear-gradient(107deg, #00023B 3.7%, #415DF1 137.92%)",
  },
  {
    label: "$10",
    value: 10,
    colors: "linear-gradient(45deg, #13C49E 0%, #2C7162 100%)",
  },
  {
    label: "$20",
    value: 20,
    colors: "linear-gradient(108deg, #1A0034 0.59%, #6A3093 59.6%)",
  },
  {
    label: "$100",
    value: 100,
    colors: "linear-gradient(76deg, #FAF23D 17.52%, #FCBE20 48.32%)",
  },
  {
    label: "$500",
    value: 500,
    colors: "linear-gradient(180deg, #28A553 0%, #107031 100%)",
  },
];

export const DEFAULT_COUNTDOWN: number = 1719878399000;

export const SPIN_ROTATE: number[] = [1, 3, 5, 7, 9, 11, 13];
