<script lang="ts" setup>
import dayjs from 'dayjs';
import { useIntervalFn } from '@vueuse/core';
import FloatingVue from 'floating-vue';
import MiddleText from '~/components/pages/chip-promo/atoms/MiddleText.vue';
import { CIRCLE_WARNING_ICON, SHIELD_OK, USER_CIRCLE_ICON } from '~/icons/icons';
import { PROMO_WHEEL_SPIN } from '~/constants';
import { FREE_SPIN_OPTIONS, SPIN_RESULT } from '~/constants/wheelSpinConfigs';
import { useBets } from '~/composables/chip-promo';
import TooltipSpinner from '~/components/pages/chip-promo/organisms/Tooltip/TooltipSpinner.vue';
import type { Bet } from '~/types/bets';
import { cn } from '~/utils';
import FairnessModal from '~/components/pages/chip-promo/organisms/Tooltip/FairnessModal.vue';
import OddsModal from '~/components/pages/chip-promo/organisms/LastSpin/OddsModal.vue';

const { wheelSpin }: any = inject(PROMO_WHEEL_SPIN);
const gameLoading = computed(
  () => wheelSpin.value?.spinGameLoading.value || false,
);
const gameID = wheelSpin.value?.game.value?.id || 0;
const betsData = useBets(gameID, 10);
const betsLoading = computed(() => betsData?.betsLoading.value || false);
const historyData = computed(
  () => betsData.bets.value?.results?.slice(0, 10) || null,
);

const highlightRows = ref<any[]>([]);

const getResultIndex = (goal: number) => {
  return SPIN_RESULT.findIndex((val) => val === goal);
};

FloatingVue.options.themes.dropdown.placement = 'left';
FloatingVue.options.themes.dropdown.triggers = ['click', 'touch'];
let betData: Bet;

const fairnessModalVisible = ref<boolean>(false);
const oddsModalVisible = ref<boolean>(false);

const handleCloseFairnessModal = () => {
  fairnessModalVisible.value = false;
};

const openFairnessModal = (data: Bet) => {
  betData = data;
  fairnessModalVisible.value = true;
};

const handleToggleOddsModal = () => {
  oddsModalVisible.value = !oddsModalVisible.value;
};

const calcTime = (time: string) => {
  const d1 = dayjs(dayjs(time).format('YYYY-MM-DDTHH:mm:ss'));
  const d2 = dayjs(dayjs().format('YYYY-MM-DDTHH:mm:ss'));

  return d2.diff(d1, 'seconds', true);
};

const checkHighlight = () => {
  if (historyData.value) {
    highlightRows.value = [];

    historyData.value.map((h: any) => {
      highlightRows.value.push({
        ...h,
        show: calcTime(h.processed_at) <= 20,
      });

      return h;
    });
  }
};

const { pause, resume } = useIntervalFn(checkHighlight, 500);
onMounted(() => {
  resume();
});

onBeforeUnmount(() => {
  pause();
});

const showMore = ref<boolean>(false);
const showMoreTxt = ref<string>('Show More');

const toggleShowMore = () => {
  showMore.value = !showMore.value;
  showMoreTxt.value = showMore.value ? 'Hide' : 'Show More';
};
</script>

<template>
  <div
    class="w-full max-w-[350px] md:max-w-[385px] xl:max-w-[333px] xl:px-3 flex flex-col gap-4"
  >
    <div class="w-full inline-flex gap-x-2 items-center relative">
      <b class="text-danger Blink" style="color: #910000; font-size: 10px">⬤</b>
      <MiddleText class="text-center text-green-60" style="font-size: 16px">
        LIVE PRIZES WON
      </MiddleText>
      <JbButton
        :class="
          cn([
            'bg-odds-gradient absolute right-0',
            'w-[62px] !h-[21px] !text-[12px]',
            '!gap-[3px] !rounded-[8px] !py-0 !px-0',
          ])
        "
        size="sm"
        @click="handleToggleOddsModal"
      >
        <p class="!text-[12px] leading-none p-0 pt-[2px]">Odds</p>
        <svg class="text-white !h-[9px] !w-[9px]">
          <use :xlink:href="CIRCLE_WARNING_ICON" />
        </svg>
      </JbButton>
    </div>

    <div
      v-if="betsLoading || gameLoading"
      class="w-full h-40 md:h-full overflow-hidden md:overflow-auto bg-transparent relative"
    >
      <JbSkeleton v-for="i in 5" :key="i" class="h-10 md:h-12 mb-10 md:mb-12" />
    </div>
    <div
      v-else
      :class="showMore ? '' : 'h-40'"
      class="w-full md:h-full overflow-hidden bg-transparent relative"
    >
      <template v-for="(hItem, h) in historyData" :key="h">
        <div
          v-if="h === 0 && !!highlightRows[h] && highlightRows[h].show"
          class="absolute w-full h-12 top-0 right-0 left-0 bottom-0 overflow-hidden rounded-lg"
        >
          <div class="w-full h-12 jb-fade-linear bg-odds-gradient" />
        </div>

        <div
          :class="{
            'bg-[#112811B2]': h % 2 === 0,
          }"
          class="flex items-center justify-between rounded-md w-full h-12 py-[14px] text-sm text-paragraph z-10 backdrop-blur-sm md:backdrop-blur-none relative"
        >
          <div
            class="pl-1 md:pl-2 w-1/12 inline-flex items-center justify-center"
          >
            <svg class="text-paragraph !h-4 !w-4">
              <use :xlink:href="USER_CIRCLE_ICON" />
            </svg>
          </div>
          <div class="pl-1 md:pl-2 w-5/12 overflow-hidden overflow-ellipsis">
            {{ hItem.display_name }}
          </div>
          <div class="w-3/12 text-center">
            {{ dayjs(hItem.processed_at).format('hh:mm A') }}
          </div>
          <div class="w-2/12 text-center">
            {{ FREE_SPIN_OPTIONS[getResultIndex(hItem.multiplier)]?.label }}
          </div>
          <div
            class="w-1/12 inline-flex items-center justify-center pr-1 md:pr-2"
          >
            <VDropdown theme="jungletooltip">
              <a>
                <svg class="text-primary h-6 w-6">
                  <use :xlink:href="SHIELD_OK" />
                </svg>
              </a>
              <template #popper>
                <TooltipSpinner
                  :bet-data="hItem"
                  @fairness-data="() => openFairnessModal(hItem)"
                />
              </template>
            </VDropdown>
          </div>
        </div>
      </template>

      <div
        class="absolute h-20 bg-xs-table w-full md:hidden bottom-0 z-20 rounded-b-[10.8px]"
      />
    </div>

    <div class="w-full flex justify-center items-center md:hidden">
      <JbButton
        :class="
          cn([
            'bg-odds-gradient',
            'w-[92px] !h-[28px] !text-[12px]',
            '!gap-[3px] !rounded-[8px] !py-0 !px-0',
          ])
        "
        size="sm"
        @click="toggleShowMore"
      >
        <p class="!text-[12px] leading-none p-0 pt-[2px]">{{ showMoreTxt }}</p>
      </JbButton>
    </div>

    <ClientOnly>
      <FairnessModal
        v-if="fairnessModalVisible"
        :bet-data="betData"
        :modal-visible="fairnessModalVisible"
        @close="handleCloseFairnessModal"
      />
    </ClientOnly>

    <ClientOnly>
      <OddsModal
        v-if="oddsModalVisible"
        :modal-visible="oddsModalVisible"
        @close="handleToggleOddsModal"
      />
    </ClientOnly>
  </div>
</template>

<style lang="css">
.v-popper--theme-jungletooltip {
  cursor: pointer;
}

.v-popper--theme-jungletooltip .v-popper__inner {
  position: relative;
  background: #021902;
  border: 1px solid #259f4f;
  border-radius: 10px;
  padding: 5px 20px;
  overflow: hidden;
}

.v-popper--theme-jungletooltip .v-popper__arrow-outer {
  border-color: #259f4f;
}

.jb-fade-linear {
  opacity: 0;
  animation: ease-s linear 1s forwards;
}

@keyframes ease-s {
  100% {
    opacity: 1;
  }
}

.Blink {
  animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
