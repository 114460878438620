import { timestamp } from '@vueuse/shared';
// @ts-ignore
import { Howl } from 'howler';
import { DEFAULT_COUNTDOWN } from '~/constants/wheelSpinConfigs';
import type { SpinData } from '~/types/chip-promo/landing-page';
import { AUTH, INIT_LOADING } from '~/constants';
import { BETS_QUERY, GAME_QUERY, LIVE_BET_SUBSCRIPTION } from '~/graphql';
import type { ID } from '~/types';
import type { Game } from '~/types/game';
import type { BetTransactions } from '~/types/bets';

export const useBets = (id: ID, limit: number) => {
  let newBetData: BetTransactions;
  const {
    result: betsResult,
    loading: betsLoading,
    subscribeToMore: betsSubscribeToMore,
  } = useQuery(
    BETS_QUERY,
    {
      game_id: id,
      limit,
      type: 'ALL',
    },
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: process.server ? 'ignore' : 'all',
      notifyOnNetworkStatusChange: true,
    },
  );

  const bets = computed(() => betsResult.value?.bets);
  const { useAddLoading } = inject(INIT_LOADING)!;
  const { loading } = inject(AUTH)!;
  const { add: addLoading, remove: removeLoading } = useAddLoading();

  loading.value = betsLoading.value;

  betsSubscribeToMore({
    document: LIVE_BET_SUBSCRIPTION,
    updateQuery(prev, { subscriptionData }) {
      if (!subscriptionData.data.liveBet) {
        return prev;
      }

      const dateVal = `${subscriptionData.data.liveBet.date}T${subscriptionData.data.liveBet.time}+00:00`;

      newBetData = {
        __typename: 'Bet',
        id: subscriptionData.data.liveBet.id,
        action_id: subscriptionData.data.liveBet.action_id,
        amount: Number(subscriptionData.data.liveBet.amount),
        bet_conditions: subscriptionData.data.liveBet.bet_conditions,
        bet_multiplier: subscriptionData.data.liveBet.bet_multiplier,
        client_seed: subscriptionData.data.liveBet.client_seed,
        created_at: dateVal,
        currency_code: subscriptionData.data.liveBet.currency_code,
        cursor: subscriptionData.data.liveBet.cursor || 0,
        display_name: subscriptionData.data.liveBet.display_name,
        final_results: subscriptionData.data.liveBet.final_results,
        game: subscriptionData.data.liveBet.game,
        high_roller: subscriptionData.data.liveBet.high_roller,
        issued_at: dateVal,
        match_id: subscriptionData.data.liveBet.match_id,
        multiplier: subscriptionData.data.liveBet.multiplier,
        nonce: subscriptionData.data.liveBet.nonce,
        normalized_amount: subscriptionData.data.liveBet.normalized_amount,
        normalized_payout: subscriptionData.data.liveBet.normalized_payout,
        payout: subscriptionData.data.liveBet.payout,
        processed_at: dateVal,
        server_seed_hashed:
          subscriptionData.data.liveBet?.server_seed_hashed || '',
        server_seed_unhashed:
          subscriptionData.data.liveBet?.server_seed_unhashed || '',
        transaction_id: subscriptionData.data.liveBet.transaction_id,
        updated_at: dateVal,
      };

      return {
        bets: {
          ...prev.bets,
          results: [newBetData, ...prev.bets.results],
        },
      };
    },
  });

  watch(
    () => betsLoading.value,
    (isLoading) => {
      if (isLoading) {
        addLoading();
      } else {
        loading.value = false;
        removeLoading(100);
      }
    },
    { immediate: betsLoading.value },
  );

  return {
    betsLoading,
    bets,
  };
};

export const useGame = (gameSlug: string) => {
  const { result, loading: spinGameLoading } = useQuery<{ game: Game }>(
    GAME_QUERY,
    {
      slug: gameSlug,
    },
  );
  const game = computed(() => result.value?.game);
  const { useAddLoading } = inject(INIT_LOADING)!;
  const { add: addLoading, remove: removeLoading } = useAddLoading();

  watch(
    () => spinGameLoading.value,
    (isLoading) => {
      if (isLoading) {
        addLoading();
      } else {
        removeLoading(100);
      }
    },
    { immediate: spinGameLoading.value },
  );

  return {
    spinGameLoading,
    game,
  };
};

export const useWheelSpinData = () => {
  const spinData = ref<SpinData | any>({
    countdown: DEFAULT_COUNTDOWN,
    startDegree: 0,
    current: timestamp(),
  });

  const updateSpinData = (
    el: string,
    value: string | number | boolean | null,
  ) => {
    spinData.value[el] = value;
  };

  return { spinData, updateSpinData };
};

export const useTickCallback = (
  callback: () => void,
  tickInterval: number,
): { tickCallback: () => void } => {
  let invokedAt = 0;

  const tickCallback = () => {
    const now = Date.now();
    if (now - invokedAt >= tickInterval) {
      callback();
      invokedAt = now;
    }
  };

  return {
    tickCallback,
  };
};

export const useAudio = (url: string, timeout = 40) => {
  let endCallbacks: Function[] = [];
  let loadCallbacks: Function[] = [];
  const onEnd = (fn: Function) => {
    endCallbacks.push(fn);
    return () => {
      endCallbacks = endCallbacks.filter(iFn => iFn !== fn);
    };
  };

  const onAudioLoad = (fn: Function) => {
    loadCallbacks.push(fn);
    return () => {
      loadCallbacks = loadCallbacks.filter(iFn => iFn !== fn);
    };
  };

  const audio = new Howl({
    src: [url],
    preload: true,
    mute: false,
    volume: 0.5,
    onload: () => {
      loadCallbacks.forEach((fn: Function) => {
        fn();
      });
    },
    onend: () => {
      endCallbacks.forEach((fn: Function) => {
        fn();
      });
    },
  });

  const { tickCallback: play } = useTickCallback(() => {
    if (audio.playing()) {
      audio.stop();
    }

    audio.play();
  }, timeout);

  const unMountLoad = () => {
    audio.unload();
  };

  return {
    audio,
    play,
    onEnd,
    onAudioLoad,
    unMountLoad,
  };
};
