<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    rect?: string;
    error?: boolean;
  }>(),
  { modelValue: false, rect: '16px', error: false },
);
</script>
<template>
  <span
    class="
      relative
      rounded-[4px] border border-solid border-paragraph
      transition-all duration-200 ease-in-out
      before:bg-green-600
    "
    :class="{
      'bg-green-600 !border-green-600': props.modelValue && !props.error,
      'border-red-600': props.error,
    }"
    :style="{
      width: `${props.rect}`,
      height: `${props.rect}`,
    }"
  >
    <svg
      class="svg absolute top-[15%] right-[10%]"
      viewBox="0 0 135 110"
    >
      <path
        :class="[props.modelValue ? 'check' : 'stroke-0 fill-none']"
        d="M126.8,14L55.7,85.1L29.2,63.4"
      />
    </svg>
  </span>
</template>
<style scoped>
.svg .check {
  stroke: white;
  fill: none;
  stroke-width: 12;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: checkSign 0.4s linear forwards;
}

@keyframes check {
  50% {
    transform: scale(1.2);
  }
}

@keyframes draw {
  0% {
    stroke-dashoffset: -400;
  }
  100% {
    stroke-dashoffset: 400;
  }
}

@keyframes checkSign {
  0% {
    stroke-dashoffset: -400;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
