<script lang="ts" setup>
import BreadcrumbWrapper from '~/components/pages/chip-promo/organisms/BreadcrumbWrapper.vue';
import SpinnerWrapper from '~/components/pages/chip-promo/organisms/MainSpinners/SpinnerWrapper.vue';
import HistoryWrapper from '~/components/pages/chip-promo/organisms/LastSpin/HistoryWrapper.vue';
import LandingPageComingSoon from '~/components/pages/chip-promo/organisms/LandingPageComingSoon.vue';
import { useWheelSpinData } from '~/composables/chip-promo';

const { spinData, updateSpinData } = useWheelSpinData();

const handleUpdateSpinData = (
  payload: {
    el: string,
    value: string | number | boolean | null
  },
) => {
  updateSpinData(payload.el, payload.value);
};
</script>
<template>
  <div
    class="
      w-full
      flex flex-col
      gap-[16px] md:gap-[35px]
    "
  >
    <div
      class="
        w-full
        lg:h-[529px]
        flex flex-col justify-center items-center lg:justify-start lg:flex-row lg:items-start
        gap-4 md:gap-8 xl:gap-[86px]
      "
    >
      <BreadcrumbWrapper />

      <SpinnerWrapper
        :spin-data="spinData"
        @update-spin-data="handleUpdateSpinData"
      />

      <HistoryWrapper />
    </div>

    <LandingPageComingSoon
      :spin-data="spinData"
    />
  </div>
</template>
