<script setup lang="ts">
interface Tab {
  label: string;
  value: number | string;
  disabled?: boolean;
}

const props = withDefaults(
  defineProps<{
    modelValue: number | string;
    tabs: Tab[] | null;
  }>(),
  {
    tabs: null,
    modelValue: 0,
  },
);
const emits = defineEmits(['update:modelValue']);

const onClickTab = (tab) => {
  emits('update:modelValue', tab);
};
</script>
<template>
  <div
    v-if="tabs"
    class="flex rounded-[10px] border-for-g-2 p-2"
  >
    <div
      v-for="(tabItem, idx) in tabs"
      :key="tabItem.value"
      class="min-w-0 flex-1"
      :class="{
        'pointer-events-none': tabItem?.disabled === false,
        'mr-1': idx + 1 !== tabs.length,
      }"
    >
      <button
        class="rounded-[10px] p-3 text-center w-full transition ease-in-out duration-200 text-paragraph font-normal"
        :class="{
          'bg-green-600': modelValue === tabItem.value,
          'hover:bg-highlights': modelValue === tabItem.value,
          'hover:bg-transparent': modelValue !== tabItem.value,
          'text-white': modelValue === tabItem.value,
          'hover:text-white': modelValue !== tabItem.value,
        }"
        variant="alternative"
        @click="() => onClickTab(tabItem.value)"
      >
        {{ tabItem.label }}
      </button>
    </div>
  </div>
</template>
