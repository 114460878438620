<script lang="ts" setup>
import type { Bet } from '~/types/bets';

const { betData } = defineProps<{
  betData: Bet;
}>();

const multiplier = (): number => {
  const data = betData;
  if (data.payout === 5) {
    return 3;
  } else if (data.payout === 10) {
    return 4;
  } else if (data.payout === 20) {
    return 5;
  } else if (data.payout === 100) {
    return 6;
  } else if (data.payout === 500) {
    return 7;
  } else {
    return data.payout;
  }
};

const degree = (multiplier() * -1 * (360 / 7) + 360 / 14).toFixed(2) + 'deg';
</script>
<template>
  <ClientOnly>
    <div class="wheel-frame">
      <NuxtImg
        :style="{
          transform: `rotate(${degree})`,
        }"
        class="wheel"
        provider="cloudflare"
        src="69d61c70-ec0c-412f-239b-d894c86eaf00/sm"
      />
      <NuxtImg
        provider="cloudflare"
        class="handle"
        src="4caab73d-1f52-4f3c-31e6-7dcb741f0600/thumb"
      />
    </div>
  </ClientOnly>
</template>
<style scoped>
.wheel-frame {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-size: contain;
  background: url(https://junglebet.com/cdn-cgi/imagedelivery/7ecf622a-216e-406e-6181-bfb26da00000/sm) no-repeat center;
  display: flex;
}

.wheel {
  width: 90%;
  height: 90%;
  position: absolute;
  left: 3%;
  right: 0;
  top: 5%;
  bottom: 0;
  margin: auto;
}

.handle {
  position: absolute;
  width: 15%;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
