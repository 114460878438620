<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core';
import { email as validEmail, minLength, required } from '@vuelidate/validators';
import { useIntervalFn, useTimeoutFn } from '@vueuse/core';
import InputWrapper from '~/components/molecules/common/InputWrapper.vue';
import { AUTH, ERRORS, PROMO_WHEEL_SPIN, SHARED_DATA, WELCOME_USER } from '~/constants';
import { CIRCLE_CHECK_ICON, SMALL_CIRCLE_WARNING_ICON } from '~/icons/icons';
import { checkAtLetter, checkDotLetter, cn, parseGraphqlError, validateEmail } from '~/utils';
import { CREATE_USER_AUTH, SEND_VERIFICATION_CODE, VALIDATE_EMAIL_CODE } from '~/graphql';
import ValidatePassword from '~/components/molecules/password/ValidatePassword.vue';
import { notify } from '~/atoms';
import SocialAuth from '~/components/pages/chip-promo/organisms/Auth/SocialAuth.vue';

const loadingForLogin = ref<boolean>(false);
const showTurnstile = ref<boolean>(false);
const isPasswordStrong = ref<boolean>(false);
const { fingerprintToken }: any = inject(SHARED_DATA);
const countdownResend = ref<number>(60);
const isVisitor = ref<boolean>(false);
const {
  tempUserId,
  email,
  verificationCode,
  sequence,
  isVerified,
  isAvailable,
  showFreeSpin,
  showRegisterAddress,
}: any = inject(PROMO_WHEEL_SPIN);

const {
  username,
  password,
  login,
  turnstileToken,
  hideTurnstile,
  affiliateCode,
}: any = inject(AUTH);
const { onLogin } = useApollo();
const runtimeConfig = useRuntimeConfig();
const router = useRouter();

const form = reactive({
  email,
  username: '',
  password: '',
  turnstileToken: false,
  verificationCode,
});

const rules = {
  email: {
    validEmail,
    required,
    checkAtLetter,
    checkDotLetter,
    minLength: minLength(3),
  },
  username: {
    required,
    minLength: minLength(3),
  },
  password: {
    required,
    minLength: minLength(8),
  },
  turnstileToken: {
    required,
  },
  verificationCode: {
    required,
    minLength: minLength(8),
  },
};

const v$ = useVuelidate(rules, form);

// sending verification code
const {
  loading: verifyLoading,
  mutate: sendVerificationCode,
  onDone: onSendEmailCodeDone,
  onError: onSendEmailCodeError,
} = useMutation(SEND_VERIFICATION_CODE);

// validating codes
const {
  loading: validateCodeLoading,
  mutate: validateEmailCode,
  onDone: onValidateCodeDone,
  onError: onValidateCodeError,
} = useMutation(VALIDATE_EMAIL_CODE);

// create user and auth
const {
  loading: createUserLoading,
  mutate: createUserAndAuth,
  onDone: onCreateUserDone,
  onError: onCreateUserError,
} = useMutation(CREATE_USER_AUTH);

// next step
const handleNext = () => {
  if (sequence.value < 3) {
    sequence.value += 1;
  }
};

// sending verification email
const handleVerifyEmail = async () => {
  if (verifyLoading.value || !(await v$.value.email.$validate())) {
    return;
  }

  if (validateEmail(form.email)) {
    email.value = form.email;

    sendVerificationCode({
      email: form.email,
      fingerprint: fingerprintToken.value,
      affiliateCode: affiliateCode.value,
    });
  } else {
    notify.error({
      title: 'Validation Failed',
      description: 'Email address is not valid!',
    });
  }
};

// validate code
const handleVerifyCode = async () => {
  if (validateCodeLoading.value || !(await v$.value.verificationCode.$validate())) {
    return;
  }

  validateEmailCode({
    id: tempUserId.value,
    code: verificationCode.value,
  });
};

const handleRegisterAccount = async () => {
  if (!(await v$.value.username.$validate()) || createUserLoading.value) {
    return;
  }

  if (!hideTurnstile.value && !form.turnstileToken) {
    showTurnstile.value = true;
    return;
  }

  checkIsVisitor();

  showTurnstile.value = false;

  username.value = form.username;
  password.value = form.password;

  createUserAndAuth({
    id: tempUserId.value,
    username: username.value,
    password: password.value,
    isVisitor: isVisitor.value,
  });
};

const resetValidate = (s: number) => {
  isAvailable.value = false;
  isVerified.value = false;
  sequence.value = s;
};

const { pause, resume } = useIntervalFn(() => {
  if (countdownResend.value < 1) {
    pause();
  }

  countdownResend.value -= 1;
}, 1000);

const resendEmail = () => {
  sequence.value = 0;
};

// sending done
onSendEmailCodeDone((response) => {
  if (response?.data?.sendVerificationCode?.status && response.data.sendVerificationCode.status === 'success') {
    notify.success({
      title: 'Verification Code Sent',
      description: 'Please check your email to verify your account.',
    });

    tempUserId.value = response?.data?.sendVerificationCode?.tempUser?.id || 0;

    countdownResend.value = 60;
    useTimeoutFn(() => {
      handleNext();
      resume();
    }, 500);
  } else {
    resetValidate(0);
    notify.error({
      title: 'Failed Verification Code Send',
      description: response?.data?.sendVerificationCode?.message || 'Something went wrong. Please try again!',
    });
  }
});

// validate code done
onValidateCodeDone((response) => {
  if (response?.data?.validateEmailCode?.status && response.data.validateEmailCode.status === 'success') {
    const verified = response?.data?.validateEmailCode?.tempUser?.is_verified || false;

    isVerified.value = verified;

    if (verified) {
      notify.success({
        title: 'Success! Email Verified',
        description: 'Your email address has been verified!',
      });

      useTimeoutFn(() => {
        handleNext();
      }, 500);
    } else {
      resetValidate(1);
      notify.error({
        title: 'Failed Code Confirmation',
        description: 'Something went wrong. Please try again!',
      });
    }
  } else {
    resetValidate(1);
    notify.error({
      title: 'Failed Code Confirmation',
      description: response?.data?.validateEmailCode?.message || 'Something went wrong. Please try again!',
    });
  }
});

// create user
onCreateUserDone(async (response) => {
  if (response?.data?.createUserAndAuth?.status && response.data.createUserAndAuth.status === 'success') {
    if (window.localStorage.getItem('jbReferrer')) {
      window.localStorage.removeItem('jbReferrer');
    }

    loadingForLogin.value = true;

    isAvailable.value = true;

    notify.success({
      title: 'Success! Account Created',
      description: 'Your account has been created!',
    });

    await onLogin(response?.data?.createUserAndAuth?.token);
    await login();
    loadingForLogin.value = false;
    sequence.value = 0;
    showFreeSpin.value = true;

    router.push({
      path: router.currentRoute.value.path,
      query: {
        ...router.currentRoute.value.query,
        type: WELCOME_USER,
        modal: 'auth',
      },
    });
  } else {
    resetValidate(3);
    notify.error({
      title: 'Failed Creating Account',
      description: response?.data?.createUserAndAuth?.message || 'Something went wrong. Please try again!',
    });
  }
});

// sending error
onSendEmailCodeError((error) => {
  resetValidate(0);
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Registering Email',
    description: parsedError.message || 'Something went wrong. Please try again!',
  });
});

// validate code error
onValidateCodeError((error) => {
  resetValidate(1);
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Code Confirmation',
    description: parsedError.message || 'Something went wrong. Please try again!',
  });
});

// create User error
onCreateUserError((error) => {
  loadingForLogin.value = false;
  resetValidate(3);
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Creating Account',
    description: parsedError.message || 'Something went wrong. Please try again!',
  });
});

const handleTurnstileCheck = async () => {
  if (!(await v$.value.turnstileToken.$validate())) {
    return;
  }

  turnstileToken.value = form.turnstileToken;
  showTurnstile.value = false;

  handleRegisterAccount();
};

const handleSubmitPassword = async () => {
  if (!(await v$.value.password.$validate())) {
    return;
  }

  password.value = form.password;

  handleNext();
};

const inputPassword = async (val: any) => {
  form.password = val;
  await v$.value.password.$validate();
};

const inputUsername = async (val: any) => {
  form.username = val;
  await v$.value.username.$validate();
};

const inputEmail = async (val: any) => {
  form.email = val;
  await v$.value.email.$validate();
};

const inputCode = async (val: any) => {
  form.verificationCode = val;
  await v$.value.verificationCode.$validate();
};

watch(
  () => form.turnstileToken,
  (tokenValue) => {
    if (tokenValue) {
      handleTurnstileCheck();
    }
  },
);

const checkIsVisitor = () => {
  if (window.localStorage.getItem('jbReferrer')) {
    isVisitor.value = window.localStorage.getItem('jbReferrer') === 'Visitor';
  }
};

onMounted(() => {
  checkIsVisitor();
});
</script>

<template>
  <form @submit.prevent="handleRegisterAccount()">
    <div
      v-if="!showFreeSpin && !showRegisterAddress"
      class="w-full flex flex-col gap-2"
    >
      <template v-if="sequence === 0">
        <InputWrapper
          :autofocus="true"
          :class="v$.email.$error ? cn('border-red-600') : (v$.email.$dirty ? cn('!border-highlights') : '')"
          :disabled="verifyLoading"
          :model-value="form.email"
          class="border-paragraph w-full hover:border-highlights"
          placeholder="Enter email address"
          @update:model-value="value => inputEmail(value)"
        >
          <JbButton
            :class="cn([
              'bg-primary-button', 'scale-100 active:scale-[0.98]', 'text-sm',
              'w-[100px]', 'gap-[7.359px]',
              'px-[17.662px] py-[11.775px]', 'disabled:text-[#1D391D]',
            ])"
            :disabled="!form.email || v$.email.$error"
            :loading="verifyLoading"
            size="md"
            @click="handleVerifyEmail"
          >
            Register
          </JbButton>
        </InputWrapper>

        <div class="h-[28.78px] w-full">
          <SocialAuth
            type="register"
            @loading="() => verifyLoading = true"
          />

          <JbInputMessage
            v-if="v$.email.$error"
            class="w-full"
          >
            <svg
              class="w-3 h-3 text-red-600"
            >
              <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
            </svg>
            {{
              v$.email.minLength.$invalid
                ? ERRORS.EMAIL_MIN_LENGTH
                : (v$.email.required.$invalid
                  ? ERRORS.EMAIL_REQUIRED
                  : ERRORS.EMAIL_INVALID)
            }}
          </JbInputMessage>
        </div>
      </template>

      <template v-else-if="sequence === 1">
        <InputWrapper
          :autofocus="true"
          :class="v$.verificationCode.$error ? cn('border-red-600') : (v$.verificationCode.$dirty ? cn('!border-highlights') : '')"
          :disabled="verifyLoading || validateCodeLoading"
          :model-value="form.verificationCode"
          class="border-paragraph w-full hover:border-highlights"
          maska="********"
          placeholder="Enter verification code"
          @update:model-value="value => inputCode(value)"
        >
          <svg
            v-if="isVerified"
            class="w-5 h-5 text-green-600"
          >
            <use :xlink:href="CIRCLE_CHECK_ICON" />
          </svg>
          <JbButton
            v-else
            :class="cn([
              'bg-primary-button', 'scale-100', 'active:scale-[0.98]', 'text-sm',
              'w-[100px]', 'gap-[7.359px]', 'px-[17.662px] py-[11.775px]', 'disabled:text-[#1D391D]',
            ])"
            :disabled="!form.verificationCode"
            :loading="validateCodeLoading"
            size="md"
            @click="handleVerifyCode"
          >
            Submit
          </JbButton>
        </InputWrapper>

        <div class="h-[28.78px] w-full">
          <div
            v-if="!form.verificationCode"
            class="
              text-sm text-paragraph font-weight-bold w-full
              inline-flex items-center gap-x-2
            "
          >
            <span>Haven't received a code yet?</span>
            <span v-if="countdownResend > 0">Please resend in {{ countdownResend }}s</span>
            <JbButton
              v-else
              :class="cn([
                'bg-primary-button', 'scale-100 active:scale-[0.98]', 'text-sm',
                'w-[100px] gap-[7.359px]', 'px-[17.662px] py-[11.775px]', 'disabled:text-[#1D391D]',
              ])"
              size="md"
              @click="resendEmail"
            >
              Resend
            </JbButton>
          </div>
          <JbInputMessage v-if="v$.verificationCode.$error">
            <svg
              class="w-3 h-3 text-red-600"
            >
              <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
            </svg>
            {{
              v$.verificationCode.minLength.$invalid
                ? 'Code must be 8 characters in length'
                : ERRORS.PROMO_CODE_REQUIRED
            }}
          </JbInputMessage>
        </div>
      </template>

      <div
        v-else-if="sequence === 2"
        class="w-full relative mb-[28.78px]"
      >
        <InputWrapper
          :class="
            (v$.password.$error || !isPasswordStrong)
              ? cn('border-red-600')
              : (v$.password.$dirty ? cn('!border-highlights') : '')
          "
          :model-value="form.password"
          class="border-paragraph w-full hover:border-highlights"
          placeholder="Create password"
          type="password"
          @update:model-value="value => inputPassword(value)"
        >
          <JbButton
            :class="cn([
              'bg-primary-button', 'scale-100 active:scale-[0.98]', 'text-sm',
              'w-[100px]', 'gap-[7.359px] px-[17.662px] py-[11.775px]', 'disabled:text-[#1D391D]',
            ])"
            :disabled="!form.password || !isPasswordStrong"
            size="md"
            @click="handleSubmitPassword"
          >
            Submit
          </JbButton>
        </InputWrapper>
        <ValidatePassword
          :password="form.password"
          class="mt-2 mb-6 absolute"
          @passed="val => isPasswordStrong = val"
        />
      </div>

      <template v-else-if="sequence === 3 && !showTurnstile">
        <InputWrapper
          :class="v$.username.$error ? cn('border-red-600') : (v$.username.$dirty ? cn('!border-highlights') : '')"
          :disabled="loadingForLogin || createUserLoading"
          :model-value="form.username"
          class="border-paragraph w-full hover:border-highlights"
          placeholder="Create username"
          @update:model-value="value => inputUsername(value)"
        >
          <span
            v-if="isAvailable"
            class="flex gap-2"
          >
            Available
            <svg
              class="w-5 h-5 text-green-600"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
          </span>
          <JbButton
            v-else
            :class="cn([
              'bg-primary-button', 'scale-100', 'active:scale-[0.98]', 'text-sm',
              'w-[100px]', 'gap-[7.359px]', 'px-[17.662px]', 'py-[11.775px]', 'disabled:text-[#1D391D]',
            ])"
            :disabled="!form.username"
            :loading="createUserLoading || loadingForLogin"
            size="md"
            @click="handleRegisterAccount"
          >
            Submit
          </JbButton>
        </InputWrapper>
        <div class="h-[28.78px] w-full">
          <JbInputMessage v-if="v$.username.$error">
            <svg
              class="w-3 h-3 text-red-600"
            >
              <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
            </svg>
            {{
              v$.username.minLength.$invalid
                ? ERRORS.USERNAME_MIN_LENGTH
                : ERRORS.USERNAME_REQUIRED
            }}
          </JbInputMessage>
        </div>
      </template>

      <template v-if="!hideTurnstile && showTurnstile">
        <div
          class="
            flex items-center flex-wrap gap-1
            w-[320px] md:w-[366px] h-[93.78px] md:h-[80px]
          "
        >
          <NuxtTurnstile
            v-model="form.turnstileToken"
            :site-key="runtimeConfig.public.turnstileSiteKey"
          />
          <JbInputMessage
            v-if="v$.turnstileToken.$error"
            class="w-full"
          >
            <svg
              class="w-3 h-3 text-red-600"
            >
              <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
            </svg>

            Turnstile reCAPTCHA is required.
          </JbInputMessage>
        </div>
      </template>
    </div>
  </form>
</template>
