<template>
  <svg
    viewBox="0 0 266 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 1L265.5 1.00002"
      stroke="url(#paint0_linear_1_1419)"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_1419"
        x1="265.5"
        y1="1.99973"
        x2="0.549784"
        y2="-1.89106"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0"
          stop-color="#091E09"
          stop-opacity="0"
        />
        <stop
          offset="0.114583"
          stop-color="#165F2C"
          stop-opacity="0.539896"
        />
        <stop
          offset="0.482346"
          stop-color="#22974A"
        />
        <stop
          offset="0.854167"
          stop-color="#165C2A"
          stop-opacity="0.513133"
        />
        <stop
          offset="1"
          stop-color="#091E09"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
