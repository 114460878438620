<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    variant?: 'primary' | 'alternative' | 'text' | 'secondary' | 'dark' | 'transparent',
    size?: 'lg' | 'md' | 'sm' | 'xs',
    shape?: 'square' | 'circle',
    hoveredVariant?: 'primary' | 'alternative' | 'text' | 'secondary' | 'dark' | null,
    align?: 'center' | 'left',
    loading?: boolean,
    disabled?: boolean
  }>(),
  {
    variant: 'primary',
    size: 'md',
    shape: 'square',
    hoveredVariant: null,
    align: 'center',
    loading: false,
    disabled: false,
  },
);
const isHovered = ref(false);

const buttonClass = computed(() => {
  const variantToClassMap = {
    primary: 'bg-primary-gradient',
    alternative: 'bg-light-green',
    dark: 'bg-chip',
    secondary: 'bg-highlights text-white',
    text: 'border-lime-gray',
    transparent: 'bg-transparent',
  };
  let _variant = props.variant;
  if (isHovered.value && props.hoveredVariant) {
    _variant = props.hoveredVariant;
  }
  return variantToClassMap[_variant];
});
const buttonSizeClass = computed(() => {
  const sizeToClassMap = {
    lg: props.shape === 'square' ? 'px-6 py-3 text-lg' : 'p-3',
    md: props.shape === 'square' ? 'px-5 py-2 text-md' : 'p-2',
    sm: props.shape === 'square' ? 'px-5 py-2 text-sm' : 'p-1',
    xs: props.shape === 'square' ? 'px-2 py-1 text-[10px] rounded-[7px]' : 'p-1',
  };
  return sizeToClassMap[props.size];
});
const alignClass = computed(() => {
  const alignToClassMap = {
    center: 'justify-center',
    left: '',
  };
  return alignToClassMap[props.align];
});
</script>

<template>
  <button
    type="button"
    v-bind="$props"
    class="
      inline-flex items-center
      transition-all ease-in duration-200
      rounded-button hover:shadow-md text-center
      justify-center gap-2 font-bold
      outline-none ring-0 hover:ring-0 shadow-none
      focus:outline-none focus:ring-0 active:outline-none active:ring-0
      focus:shadow-none active:shadow-none
      disabled:bg-green-95 disabled:bg-none disabled:cursor-not-allowed
      disabled:active:scale-100 disabled:text-disabled-paragraph
      disabled:border-1 disabled:border-line disabled:border-solid
    "
    :class="[
      buttonClass,
      buttonSizeClass,
      alignClass,
      {
        'border border-solid': variant === 'text'
      },
    ]"
    :disabled="loading || disabled"
    @mouseover="isHovered = true"
    @mouseout="isHovered = false"
  >
    <svg
      v-if="loading"
      class="animate-spin h-6 w-6 text-green-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>

    <slot
      v-if="!loading"
      name="startIcon"
    />
    <slot v-if="!loading" />
  </button>
</template>
