<script lang="ts" setup>
import JbCheckboxLabel from '~/atoms/JbCheckboxLabel.vue';
import JbCheckbox from '~/atoms/JbCheckbox.vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    rect?: string;
    error?: boolean;
  }>(),
  { modelValue: false, rect: '16px', error: false },
);
const emit =
  defineEmits<{
    (e: 'update:modelValue', value: boolean): void;
  }>();

const handleToggleCheck = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>
<template>
  <JbCheckboxLabel
    :model-value="modelValue"
    @update:model-value="handleToggleCheck"
  >
    <template #checkbox>
      <div class="flex items-start">
        <JbCheckbox
          :error="error"
          :model-value="modelValue"
          :rect="rect"
        />
      </div>
    </template>
    <slot />
  </JbCheckboxLabel>
</template>
