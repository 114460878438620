<script lang="ts" setup>
import { useTimeoutFn } from '@vueuse/core';
import { useMutation } from '@vue/apollo-composable';
import ClientSeedModal from './ClientSeedModal.vue';
import InputCheckbox from '~/components/molecules/common/InputCheckbox.vue';
import ButtonText from '~/components/pages/chip-promo/atoms/ButtonText.vue';
import type { SpinData } from '~/types/chip-promo/landing-page';
import { AUTH, PROMO_WHEEL_SPIN } from '~/constants';
import RegisterUser from '~/components/pages/chip-promo/organisms/MainSpinners/RegisterUser.vue';
import { useAudio } from '~/composables/chip-promo';
import { CHIP_PROMO_FREE_SPIN_MUTATION } from '~/graphql';
import { parseGraphqlError } from '~/utils';
import { SPIN_RESULT } from '~/constants/wheelSpinConfigs';
import { notify } from '~/atoms';
import RegisterAddress from '~/components/pages/chip-promo/organisms/MainSpinners/RegisterAddress.vue';

const { spinData } = defineProps<{
  spinData: SpinData;
}>();

const emits = defineEmits<{
  (
    e: 'updateSpinData',
    payload: { el: string; value: string | number | boolean | null },
  ): void;
  (e: 'openWonModal', payload: boolean): void;
}>();

const handleUpdateSpinData = (payload: {
  el: string;
  value: string | number | boolean | null;
}) => {
  emits('updateSpinData', payload);
};

const { me, isLoggedIn, refetch }: any = inject(AUTH);
const {
  degreeVal,
  showFreeSpin,
  showRegisterAddress,
  spinResult,
  agreeTos,
}: any = inject(PROMO_WHEEL_SPIN);
const loading = ref<boolean>(false);
const isCheckedTos = ref<boolean>(true);
const freeSpinDisabled = ref<boolean>(false);

const {
  mutate: spinMutate,
  loading: spinLoading,
  onDone: onSpinDone,
  onError: onSpinError,
} = useMutation(CHIP_PROMO_FREE_SPIN_MUTATION);

onSpinError((error) => {
  loading.value = false;
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Free Spin',
    description:
      parsedError.message || 'Something went wrong. Please try again!',
  });
});

onSpinDone(async ({ data }) => {
  const index = SPIN_RESULT.findIndex(
    val => val === (data?.chipPromoWheelSpin?.payoutMultiplier || 1),
  );

  const {
    audio,
    onAudioLoad,
    play: soundWheelSpin,
  } = useAudio('/assets/audio/wheel-spin.wav');

  onAudioLoad(() => {
    soundWheelSpin();

    refetch();
    degreeVal.value = 0;
    const flag = spinData.startDegree > 0 ? (360 / 7) * spinResult.value : 0;
    const degreeValue =
      spinData.startDegree + 360 * 7 + flag - (360 / 7) * index;

    handleUpdateSpinData({
      el: 'startDegree',
      value: degreeValue,
    });

    spinResult.value = index;

    useTimeoutFn(() => {
      emits('openWonModal', true);
      loading.value = false;
    }, 5200);
  });

  audio.load();
});

const handleFreeSpin = () => {
  if (!isLoggedIn.value && !agreeTos.value) {
    isCheckedTos.value = false;
    return;
  }

  isCheckedTos.value = true;

  if (loading.value || spinLoading.value) {
    return;
  }

  if (isLoggedIn.value) {
    if ((me.value?.free_spins || 0) > 0) {
      loading.value = true;
      spinMutate();
    } else if (!me.value?.address) {
      notify.info({
        title: 'Free Spin Notification.',
        description:
          'While your account no longer has any Free Spins left, ' +
          'you have the opportunity to receive an extra Free Spin simply by ' +
          'registering your address. Don\'t miss out!',
      });

      showFreeSpin.value = false;
      showRegisterAddress.value = true;
    } else {
      notify.warning({
        title: 'Free Spin Notification.',
        description: 'Your account no longer has any remaining Free Spins.',
      });
      freeSpinDisabled.value = true;
    }
  } else {
    showFreeSpin.value = false;
  }
};

const handleCheckTos = (val: boolean) => {
  isCheckedTos.value = val;
  agreeTos.value = val;
};

const seedModalVisible = ref<boolean>(false);

const toggleSeedModal = () => {
  seedModalVisible.value = !seedModalVisible.value;
};
</script>

<template>
  <div class="flex flex-col justify-start gap-2 w-full">
    <template v-if="showFreeSpin || isLoggedIn">
      <template v-if="showRegisterAddress">
        <RegisterAddress />
      </template>
      <template v-else>
        <JbButton
          :class="{
            // 'mb-6': isLoggedIn,
            '!bg-green-950': !isLoggedIn && !agreeTos,
            '!bg-none': !isLoggedIn && !agreeTos,
            '!cursor-not-allowed': !isLoggedIn && !agreeTos,
            '!text-disabled-paragraph': !isLoggedIn && !agreeTos,
            '!border-[2px]': !isLoggedIn && !agreeTos,
            '!border-line': !isLoggedIn && !agreeTos,
            '!border-solid': !isLoggedIn && !agreeTos,
          }"
          :loading="loading || spinLoading"
          class="w-full scale-100 active:scale-[0.995] bg-spinner-button text-green-950"
          @click="handleFreeSpin"
        >
          <ButtonText
            :class="{
              'text-disabled-paragraph': !isLoggedIn && !agreeTos,
            }"
          >
            SPIN TO WIN!
          </ButtonText>
        </JbButton>

        <InputCheckbox
          v-if="!isLoggedIn && showFreeSpin"
          :error="!isCheckedTos"
          :model-value="agreeTos"
          @update:model-value="(value) => handleCheckTos(value)"
        >
          <div
            :class="{
              'text-red-600': !isCheckedTos,
              'text-paragraph': isCheckedTos,
            }"
            class="text-xs leading-[14.4px] font-medium mt-[1px]"
          >
            I confirm that I am over
            <span class="font-bold"> 18 years </span>
            old and have read, and accept the
            <a
              class="font-bold underline"
              href="/terms"
              target="_blank"
              @click.stop
            >Terms</a>
            &
            <a
              class="font-bold underline"
              href="/policies"
              target="_blank"
              @click.stop
            >Privacy Policy</a>
          </div>
        </InputCheckbox>
      </template>
      <!-- client seed modal -->
      <div
        v-if="isLoggedIn"
        class="w-full flex justify-center items-center"
      >
        <JbButton
          class="w-[102px] !h-[21px] !text-[12px] !rounded-[8px] !py-0 !px-0"
          size="sm"
          variant="alternative"
          @click="toggleSeedModal()"
        >
          <p class="!text-[12px] leading-none p-0">
            My client seed
          </p>
        </JbButton>
        <ClientOnly>
          <ClientSeedModal
            v-if="isLoggedIn && showFreeSpin"
            :modal-visible="seedModalVisible"
            @close="toggleSeedModal"
          />
        </ClientOnly>
      </div>
      <!-- client seed modal -->
    </template>
    <template v-else>
      <RegisterUser />
    </template>
  </div>
</template>
