<script lang="ts" setup>
import type { Bet } from '~/types/bets';
import FormField from '~/components/molecules/form/FormField.vue';
import JInput from '~/atoms/JInput.vue';
import { useVerifyFairness } from '~/composables/fairness';
import JTabs from '~/atoms/JTabs.vue';
import Prism from 'prismjs'; // Import Prism.js
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-javascript';

const MAX_POSSIBLE = 100000000;

enum Payouts {
  ONE_IN_7777 = 500,
  ONE_IN_5000 = 100,
  ONE_IN_500 = 20,
  ONE_IN_100 = 10,
  ONE_IN_25 = 5,
  ONE_IN_4 = 2,
  ONE_IN_1_66 = 1,
}

const generateBytesCode: string = `/**
Generates ​a sequence of random bytes using HMAC - JBF Engine
 *
@param {Object} params - Function parameters {serverSeed, clientSeed, nonce, cursor}
@property {String} params.serverSeed - Server seed for the hash function
@property {String} params.clientSeed - Client seed for the hash function
@property {Number} params.nonce - Nonce for the hash function
@property {Number} params.cursor - Cursor to keep track of the progress
 */
function* randomByteGenerator({ serverSeed, clientSeed, nonce, cursor }) {

  // Calculate the round index based on the provided cursor.
  let roundIndex = Math.floor(cursor / 32);
  // Calculate the position of the cursor inside the current round.
  let cursorInRound = cursor % 32;

  // Infinite loop to keep generating bytes.
  while (true) {

    // Create hash with server seed and update it with clientSeed, nonce and round index.
    const hmac = createHmac('sha256', serverSeed);
    hmac.update(clientSeed:nonce:roundIndex');

    // Digest the updated hash to create a buffer.
    const outputBuffer = hmac.digest();

    // Use the cursor to pick bytes from the buffer and yield them until end of the round.
    while (cursorInRound < 32) {
      yield Number(outputBuffer[cursorInRound]);
      cursorInRound += 1;
    }

    // Once the round ends, reset the position of the cursor inside the round,
    // and increase the round index to start a new round.
    cursorInRound = 0;
    roundIndex += 1;
  }
}`;
const generateFloatsCode: string = `
// Bytes -> Floats Conversion (Junglebet.com)
function generateFloats ({ serverSeed, clientSeed, nonce, cursor, count }) {
  // RNG function
  const rng = generateBytes({ serverSeed, clientSeed, nonce, cursor });

  // Declare bytes as an array, preallocate size
  const bytes = new Array(count * 4);

  const countLen = bytes.length;
  let iterCounter = 0;

  // Populate bytes array from RNG output
  while (iterCounter < countLen) {
    bytes[iterCounter++] = rng.next().value;
  }

  // Pre-calculate divisors
  const divisors = Array.from({ length: 4 }, (_, i) => 256 ** (i + 1));

  // Return bytes as floats
  return _.chunk(bytes, 4).map(bytesChunk =>
    bytesChunk.reduce((result, value, i) => {
      const partialResult = value / divisors[i];
      return result + partialResult;
    }, 0)
  );
};
`;

const highlightedGenerateFloatsCode = Prism.highlight(
  generateFloatsCode,
  Prism.languages.javascript,
  'javascript',
);
const highlightedGenerateBytesCode = Prism.highlight(
  generateBytesCode,
  Prism.languages.javascript,
  'javascript',
);

onMounted(async () => {
  Prism.highlightAll();
});

const tabIndex = ref(1);

const Tabs = [
  {
    label: 'Fairness Form',
    value: 1,
  },
  {
    label: 'How does it work?',
    value: 2,
  },
];

const onTabChanged = (data: any) => {
  tabIndex.value = data;
};

const { betData } = defineProps<{
  betData: Bet;
}>();

const form = ref({
  serverSeed: betData.server_seed_unhashed,
  clientSeed: betData.client_seed,
  serverResult: betData.final_results,
});

const { loading, first, verified } = useVerifyFairness({
  // @ts-ignore
  serverSeed: computed(() => form.value.serverSeed),
  clientSeed: computed(() => form.value.clientSeed),
  nonce: computed(() => betData.nonce),
  count: computed(() => 1),
  range: [0, MAX_POSSIBLE],
});

const verifyResultTxt = computed(() => {
  return first.value || 0;
});

const verifyResult = computed(() => {
  const gameResult = first.value || 0;
  return gameResult <= MAX_POSSIBLE * (1 / 7777)
    ? Payouts.ONE_IN_7777
    : gameResult <= MAX_POSSIBLE * (1 / 5000)
      ? Payouts.ONE_IN_5000
      : gameResult <= MAX_POSSIBLE * (1 / 500)
        ? Payouts.ONE_IN_500
        : gameResult <= MAX_POSSIBLE * (1 / 100)
          ? Payouts.ONE_IN_100
          : gameResult <= MAX_POSSIBLE * (1 / 25)
            ? Payouts.ONE_IN_25
            : gameResult <= MAX_POSSIBLE * (1 / 4)
              ? Payouts.ONE_IN_4
              : Payouts.ONE_IN_1_66;
});
</script>

<template>
  <div class="w-full flex flex-col gap-3 items-start mt-3 text-xs md:text-sm">
    <div class="my-2 w-full">
      <JTabs
        :modelValue="tabIndex"
        :tabs="Tabs"
        @update:model-value="onTabChanged"
      />
    </div>

    <div v-show="tabIndex == 1" class="w-full">
      <div class="my-2 w-full text-left">
        <span>Server Seed</span>
        <FormField class="w-full hover:border-highlights">
          <JInput v-model="form.serverSeed" placeholder="Server Seed" />
        </FormField>
      </div>
      <div class="my-2 w-full text-left">
        <span>Client Seed</span>
        <FormField class="w-full hover:border-highlights">
          <JInput v-model="form.clientSeed" placeholder="Client seed" />
        </FormField>
      </div>
      <div class="flex">
        <div class="my-2 w-6/12 text-left px-1">
          <span>Spin Result</span>
          <FormField class="w-full hover:border-highlights">
            <JInput v-model="form.serverResult" placeholder="Client seed" />
          </FormField>
        </div>
        <div class="my-2 w-6/12 text-left px-1">
          <span>Verification Result</span>
          <FormField class="w-full hover:border-highlights">
            <JInput v-model="verifyResultTxt" placeholder="Client seed" />
          </FormField>
        </div>
      </div>
      <JbButton
        v-if="loading"
        :loading="loading"
        class="w-full"
        variant="primary"
      >
        Verify
      </JbButton>
      <div v-if="verified && !loading" class="big-result w-full">
        Payout: ${{ verifyResult }}
      </div>
    </div>
    <div v-show="tabIndex == 2" class="w-full">
      <div class="">
        <p class="text-left my-3">
          We use 2 functions to generate the results for the wheelspin <br />The
          generate floats creates a random number using server and client seeds
          as sources for the result, based on the result we apply the odds to
          deliver the prize
        </p>
        <pre class="junglebet-code">
        <code v-html="highlightedGenerateFloatsCode"></code>
      </pre>
        <p class="text-left my-3">And below the generateBytes function</p>
        <pre class="junglebet-code">
        <code v-html="highlightedGenerateBytesCode"></code>
      </pre>
      </div>
    </div>
  </div>
</template>

<style scoped>
.big-result {
  font-weight: bold;
  text-align: center;
  font-size: 3em;
}

.small {
  font-size: 1em;
}

.junglebet-code {
  text-align: left;
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;
  text-shadow: none;
  overflow-x: scroll;
  overflow-y: hidden;
  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code,
  Fira Mono,
  Consolas,
  Menlo,
  Courier,
  monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
