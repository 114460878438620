export default defineComponent({
  name: 'FooterContainer',
  setup (props, { slots }): { slots: any } {
    return {
      slots,
    };
  },
  render () {
    const { slots } = this;
    return (
      <footer>
        <nav
          class="
            w-full
            flex flex-col items-center
            px-3 pb-16 md:pb-10
            text-paragraph
          "
        >
          {slots && slots?.default()}
        </nav>
      </footer>
    );
  },
});
