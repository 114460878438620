import type { SlideContent } from '~/types';

export type SlideConfig = {
  autoplay?: boolean;
  delay?: number;
  showDots?: boolean;
  showArrow?: boolean;
}

export default function <T extends SlideContent>() {
  const contents = ref<T[]>([]);
  const slidesLength = computed(() => contents.value.length);
  const currentIndex = ref<number>(0);
  const selectedContent = ref<T | null>(null);

  const sliderState = reactive({
    slidesLength,
    currentIndex,
    selectedContent,
  });

  const sliderConfig: SlideConfig = reactive({
    autoplay: false,
    delay: 3000,
    showDots: true,
    showArrow: false,
  });

  let showInterval: ReturnType<typeof setInterval>;

  function setSelectedContent(content: any) {
    sliderState.selectedContent = content;
  }

  function slideNext() {
    sliderState.currentIndex < contents.value.length - 1
      ? sliderState.currentIndex++
      : (sliderState.currentIndex = 0);

    setSelectedContent(contents.value[sliderState.currentIndex]);
  }

  function slidePrevious() {
    sliderState.currentIndex > 0
      ? sliderState.currentIndex--
      : (sliderState.currentIndex = contents.value.length - 1);

    setSelectedContent(contents.value[sliderState.currentIndex]);
  }

  onMounted(() => {
    if (sliderState.slidesLength > 0) {
      setSelectedContent(contents.value[0]);

      if (sliderConfig.autoplay) {
        showInterval = setInterval(() => {
          slideNext();
        }, sliderConfig.delay);
      }
    }
  });

  onBeforeUnmount(() => clearInterval(showInterval));

  return {
    contents,
    sliderState,
    sliderConfig,
    slideNext,
    slidePrevious,
  };
}
