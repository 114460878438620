export const useCountdown = (remainingTime: number) => {
  let remain: number = remainingTime / 1000;
  let secs: number = 0;
  let minutes: number = 0;
  let hours: number = 0;
  let days: number = 0;

  if (remain > 0) {
    secs = remain % 60;
    remain = (remain - secs) / 60;
  }

  if (remain > 0) {
    minutes = remain % 60;
    remain = (remain - minutes) / 60;
  }

  if (remain > 0) {
    hours = remain % 24;
    remain = (remain - hours) / 24;
  }

  if (remain > 0) {
    days = remain;
  }

  return { days, hours, minutes, secs };
};

export const useTwoNumber = (num: number): string | number => {
  return Math.round(num) < 10 ? `0${num.toFixed(0)}` : num.toFixed(0);
};
