<script lang="ts" setup>
withDefaults(
  defineProps<{
    modelValue: any;
    disabled?: boolean;
    autofocus?: boolean;
    readonly?: boolean;
    required?: boolean;
    loading?: boolean;
    type?: string;
    placeholder?: any;
    maska?: any;
  }>(),
  {
    modelValue: null,
    loading: false,
    type: 'text',
    disabled: false,
    placeholder: null,
    maska: null,
  },
);

const emit =
  defineEmits<{
    (e: 'update:modelValue', value: any): void;
    (e: 'keypress', value: any): void;
  }>();

const handleChange = (value: any) => {
  emit('update:modelValue', value);
};

const handleKeyup = (value: any) => {
  emit('keypress', value);
};
</script>

<template>
  <JbInputGroup :loading="loading">
    <template #input>
      <JbInputInner
        :autofocus="autofocus"
        :disabled="disabled"
        :maska="maska"
        :model-value="modelValue"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
        :type="type"
        @keypress="handleKeyup"
        @update:model-value="handleChange"
      />
    </template>
    <slot />
  </JbInputGroup>
</template>
