<script lang="ts" setup>
import { cn } from '~/utils';
import ProfileForm from '~/components/pages/chip-promo/organisms/Auth/ProfileForm.vue';

const { modalVisible } = defineProps<{
  modalVisible: boolean;
}>();

const emits = defineEmits<{
  (e: 'close:loginModal', payload: boolean): void;
  (e: 'logout'): void;
}>();

const handleModalClose = () => {
  emits('close:loginModal', false);
};

const logout = () => {
  emits('logout');
};
</script>

<template>
  <JbSimpleModal
    :is-open="modalVisible"
    :no-header="false"
    class="inline-flex justify-center items-start md:items-center"
    class-name="w-[480px] h-full bg-chip my-8 md:my-0 px-[26px] py-[26px]"
    @close:simple-modal="handleModalClose"
  >
    <template #header>
      <div class="font-bold flex gap-x-[10px] md:gap-x-[25px]">
        <p>My Account</p>
        <JbButton
          :class="cn(['hover:text-zinc-100 !items-start', '!px-0 !py-0'])"
          class="text-paragraph"
          size="md"
          @click="logout"
        >
          Logout
        </JbButton>
      </div>
    </template>

    <ProfileForm
      @close="handleModalClose"
    />
  </JbSimpleModal>
</template>
