<script lang="ts" setup>
import HeaderContainer from '~/atoms/layouts/HeaderContainer';
import JButton from '~/atoms/JButton.vue';
import { AUTH, PROMO_WHEEL_SPIN } from '~/constants';
import { LOGOUT_MUTATION } from '~/graphql';
import { cn, parseGraphqlError } from '~/utils';
import { notify } from '~/atoms';
import LoggedOut from '~/components/pages/chip-promo/organisms/Auth/LoggedOut.vue';
import ProfileModal from '~/components/pages/chip-promo/organisms/Auth/ProfileModal.vue';
import { WALLET_ICON } from '~/icons/icons';
// @ts-expect-error
import Apollo from '#apollo';

const clientConfig = Apollo.clients.default;

const cookie = useCookie(clientConfig.tokenName);

const {
  isLoggedIn,
  me,
  username,
  password,
  turnstileToken,
  hideTurnstile,
  isVisibleProfile,
}: any = inject(AUTH);
const {
  email,
  showFreeSpin,
  showRegisterAddress,
  sequence,
  verificationCode,
  isAvailable,
  isVerified,
  degreeVal,
}: any = inject(PROMO_WHEEL_SPIN);

const { mutate, onDone, loading, onError } = useMutation(LOGOUT_MUTATION);
const { onLogout } = useApollo();

const toggleProfileModal = () => {
  isVisibleProfile.value = !isVisibleProfile.value;
};

const resetFields = () => {
  degreeVal.value = 25;
  sequence.value = 0;
  isAvailable.value = false;
  isVerified.value = false;
  showRegisterAddress.value = false;
  email.value = '';
  username.value = '';
  password.value = '';
  verificationCode.value = '';
  if (!hideTurnstile.value) {
    turnstileToken.value = false;
  }
};

const logout = async () => {
  if (loading.value) {
    return;
  }

  if (isVisibleProfile.value) {
    toggleProfileModal();
  }

  showRegisterAddress.value = false;
  showFreeSpin.value = true;

  await mutate();
};

onDone(async () => {
  notify.success({
    title: 'Logout Success',
    description: 'You were successfully logged out.',
  });
  cookie.value = null;
  showFreeSpin.value = true;
  showRegisterAddress.value = false;
  sequence.value = 0;
  resetFields();

  await onLogout();
});

onError((error) => {
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Logout Failed :(',
    description:
      parsedError.message || 'Oops, Something went wrong. Please try again.',
  });
});

const hasSpins = computed(
  () =>
    `${me.value?.free_spins >= 0 ? me.value?.free_spins : 'No'} spin${me.value?.free_spins !== 1 ? 's' : ''}`,
);

const wallets = computed(() =>
  me.value?.wallets?.reduce((accumulator: number, currentValue: any) => {
    return accumulator + currentValue.available_balance || 0;
  }, 0),
);
</script>

<template>
  <HeaderContainer class="md:pb-10 md:px-8 md:h-32">
    <LoggedOut v-if="!isLoggedIn" />
    <div
      v-else
      class="flex items-center pr-10 md:pr-0 gap-x-2 md:gap-x-4"
    >
      <JbButton
        :class="
          cn(['hover:text-zinc-100', '!px-2 !py-0 md:px-[17.7px] md:py-3'])
        "
        class="text-paragraph"
        size="md"
        @click="toggleProfileModal"
      >
        {{ me.username }}
      </JbButton>
      <JButton
        :class="
          cn([
            'py-[6px] px-[12px] sm:px-[10px] sm:py-[10px]',
            'sm:w-auto sm:h-[40px] !gap-x-1',
            'text-paragraph hover:text-zinc-100',
          ])
        "
        size="sm"
        variant="alternative"
        @click="toggleProfileModal"
      >
        <NuxtImg
          provider="cloudflare"
          alt="chip spinner"
          class="w-5 h-5 hidden sm:block"
          src="f3790e70-cfd5-4e67-2049-bcfe67992d00/thumb"
        />

        <span class="text-nowrap">
          {{ hasSpins }}
        </span>
        |
        <svg class="h-3 w-3">
          <use :xlink:href="WALLET_ICON" />
        </svg>
        {{
          Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(wallets)
        }}
      </JButton>
      <JButton
        :class="
          cn([
            'py-[6px] px-[12px] sm:px-[10px] sm:py-[10px]',
            'sm:w-[92px] sm:h-[40px]',
            'text-paragraph hover:text-zinc-100',
          ])
        "
        :loading="loading"
        size="sm"
        variant="alternative"
        @click="logout"
      >
        Log out
      </JButton>
    </div>
    <ClientOnly>
      <ProfileModal
        v-if="isVisibleProfile"
        :modal-visible="isVisibleProfile"
        @logout="logout"
        @close:login-modal="toggleProfileModal"
      />
    </ClientOnly>
  </HeaderContainer>
</template>
