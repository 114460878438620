<script lang="ts" setup>
import { FREE_SPIN_OPTIONS, SPIN_ROTATE } from '~/constants/wheelSpinConfigs';
import JbStar from '~/atoms/JbStar.vue';
import FreeSpin from '~/components/pages/chip-promo/organisms/MainSpinners/FreeSpin.vue';
import SpinnerTop from '~/components/pages/chip-promo/atoms/SpinnerTop.vue';
import TopRing from '~/components/pages/chip-promo/atoms/TopRing.vue';
import SpinCenter from '~/components/pages/chip-promo/atoms/SpinCenter.vue';
import type { SpinData } from '~/types/chip-promo/landing-page';
import WonModal from '~/components/pages/chip-promo/organisms/WonModal.vue';
import { PROMO_WHEEL_SPIN } from '~/constants';

const { spinData } = defineProps<{
  spinData: SpinData;
}>();

const emits = defineEmits<{
  (e: 'updateSpinData', payload: { el: string, value: string | number | boolean | null }): void;
}>();

const { spinResult, degreeVal }: any = inject(PROMO_WHEEL_SPIN);

const wonModalVisible = ref<boolean>(false);

const onToggleModal = (val: boolean) => {
  wonModalVisible.value = val;
};

const handleUpdateSpinData = (payload: { el: string, value: string | number | boolean | null }) => {
  emits('updateSpinData', payload);
};

const calculateDegree = (index: number) => (
  SPIN_ROTATE[((index - spinResult.value) < 0
    ? (index - spinResult.value) + 7
    : (index - spinResult.value))] * 25.71 - 25.71 + degreeVal.value
);
</script>

<template>
  <div
    class="
      w-[361px] md:w-[412px] md:h-[498px]
      max-w-xs md:max-w-fit
      inline-flex flex-col items-center
      gap-[15px] md:gap-[20px] lg:gap-[29px]
      relative
    "
  >
    <FreeSpin
      :spin-data="spinData"
      @update-spin-data="handleUpdateSpinData"
      @open-won-modal="onToggleModal"
    />

    <!--  Wheel Spinner  -->
    <div
      class="
        w-[303px] h-[326px] md:w-[366px] md:h-[380px]
        relative
      "
    >
      <div
        class="
          w-[59px] h-[24px] md:w-[69px] md:h-[27px]
          bg-spinner-frame-top shadow-sm
          border-0 rounded-t-[4px]
          mx-auto
        "
      />

      <SpinnerTop
        class="
          w-[43px] h-[55px] md:w-[50px] md:h-[64px]
          absolute top-[6px] left-[129px] md:top-[5px] md:left-[157.6px]
          z-102
        "
      />

      <TopRing />

      <div
        class="
          w-[303px] h-[303px] md:w-[366px] md:h-[366px]
          rounded-full
          border-y-[15px] border-x-[14px] border-solid border-green-600
          mx-auto -mt-[7px]
          relative
          z-100
        "
      >
        <div
          class="
            w-[283px] h-[283px] md:w-[340px] md:h-[340px]
            rounded-full
            bg-spinner-inner-ring
            absolute -top-[4px] -left-[4px] md:-top-[2px] md:-left-[1px]
          "
        >
          <div
            :style="{
              transform: `rotate(calc(${spinData.startDegree}deg))`
            }"
            class="
              w-[267px] h-[267px] md:w-[324px] md:h-[324px]
              inset-0
              m-[8px]
              relative
              overflow-hidden
              rounded-full
              transition-transform
              duration-[5s]
              ease-[cubic-bezier(0,.3,0,1)]
            "
          >
            <div
              v-for="(spin, index) in FREE_SPIN_OPTIONS"
              :key="index"
              :style="{
                clipPath: 'polygon(50% 0%, 100% 0%, 50% 100%, 0% 0%)',
                transform: `rotate(calc(${SPIN_ROTATE[index] * 25.71 - 25.71 + degreeVal}deg))`,
                backgroundImage: spin.colors,
              }"
              class="
                absolute
                w-[50.43%] h-[52%] md:w-[51.5%]
                left-[24%] md:left-[24.12%] bottom-1/2
                origin-bottom
                rounded-[10px]
                pt-5
              "
            >
              <div
                class="
                  w-full
                  flex flex-col justify-center items-center
                  gap-2
                "
              >
                <JbStar class="w-[28px] h-[25.72px] md:w-[37px] md:h-[34px]" />
                <div
                  :class="[
                    (calculateDegree(index) < 170 ? '-rotate-90' : 'rotate-90'),
                    (spin.value < 500 ? 'text-center mt-3' : (calculateDegree(index) < 170 ? 'text-left -mt-7' : 'text-right -mt-7')),
                  ]"
                  class="
                    text-[#ECF0EC] text-2xl md:text-xxl font-bold
                    transform
                    w-full
                    spin-text
                  "
                >
                  {{ spin.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SpinCenter
        class="
          w-[71px] h-[76px] md:w-[86px] md:h-[89px]
          absolute
          z-102
          top-[134px] left-[116px] md:top-[158px] md:left-[140px]
        "
      />
    </div>

    <WonModal
      :modal-visible="wonModalVisible"
      @close:won-modal="onToggleModal"
    />
  </div>
</template>

<style scoped>
.spin-text {
  text-shadow: 1px 1px 1px #0a7350;
}
</style>
