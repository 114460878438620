<script lang="ts" setup>
import { useToggle } from '@vueuse/core';
import { BASE_CLOSE_ICON } from '~/icons/icons';

const expandBar = ref<boolean>(false);

const toggleOpenBar = useToggle(expandBar);
</script>

<template>
  <ClientOnly>
    <JbFloatingBar
      :class="[
        expandBar ? 'w-[280px] h-[175px] md:w-[360px] md:h-[202px]' : 'w-[100px] h-[80px] md:w-[125px] md:h-[125px]'
      ]"
      class="p-2 md:py-4 flex justify-center"
    >
      <div class="w-full h-full relative max-w-[260px] md:max-w-[320px]">
        <svg
          v-if="expandBar"
          class="
            text-zinc-100 cursor-pointer
            absolute top-0 right-0
            w-3 h-3 md:w-4 md:h-4
          "
          @click="toggleOpenBar()"
        >
          <use :xlink:href="BASE_CLOSE_ICON" />
        </svg>

        <div
          :class="[
            expandBar ? 'flex-row' : 'flex-col justify-center'
          ]"
          class="flex items-center"
        >
          <JbShadowChip
            :class="[
              expandBar ? 'w-[90px] h-[90px]' : 'w-12 h-12 md:w-[80px] md:h-[80px]'
            ]"
            @click="toggleOpenBar()"
          />

          <p
            :class="[
              expandBar ? 'text-base md:text-xxl' : 'text-xxs md:text-xs hover:underline'
            ]"
            class="
              font-bold md:ml-2
            "
            @click="toggleOpenBar()"
          >
            Found a Chip IRL?
          </p>
        </div>

        <div
          v-if="expandBar"
          class="text-[12px] md:text-sm mt-1 text-zinc-100 pl-1 font-bold leading-none"
        >
          Congrats!
        </div>

        <div
          v-if="expandBar"
          class="text-paragraph text-[12px] md:text-sm pl-1 mt-1 leading-none"
        >
          Scan the QR code, register your email address, and receive a complimentary
          <span class="font-bold">10 FREE SPINS!</span> Enjoy the chip drip :)
        </div>
      </div>
    </JbFloatingBar>
  </ClientOnly>
</template>
