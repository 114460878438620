<script lang="ts" setup>
import ContentContainer from '~/atoms/layouts/ContentContainer';
import ContentWrapper from '~/atoms/layouts/ContentWrapper';
import LandingPageStage from '~/components/pages/chip-promo/organisms/LandingPageStage.vue';
import LandingPageSubHead from '~/components/pages/chip-promo/molecules/LandingPageSubHead.vue';
import PromoPageFooter from '~/components/pages/chip-promo/organisms/PromoPageFooter.vue';

const jbReferrer = useState('jbReferrer');
const host = computed(() => jbReferrer.value?.host || '');
const referer = computed(() => jbReferrer.value?.referer || null);
const runtimeConfig = useRuntimeConfig();

onMounted(() => {
  const serverHost = window.location.host;
  const luckyChipHost = runtimeConfig.public.luckyChipHost;
  if (serverHost === luckyChipHost) {
    window.localStorage.setItem('jbReferrer', 'Visitor');
  } else if (referer.value && referer.value !== '') {
    // Parse the referrer URL
    const referrerUrl = new URL(referer.value);
    // Parse the current local URL
    const localUrl = new URL(window.location.href);

    // Compare the paths of the referrer URL and the local URL
    if (
      serverHost !== host.value ||
      (serverHost === host.value && referrerUrl.pathname !== localUrl.pathname)
    ) {
      // Execute your function here
      window.localStorage.setItem('jbReferrer', 'Visitor');
    }
  }
});
</script>

<template>
  <ContentContainer>
    <ContentWrapper
      class="
        flex flex-col justify-center
        gap-5 md:gap-[43px]
      "
    >
      <LandingPageSubHead />

      <ClientOnly>
        <LandingPageStage />
      </ClientOnly>

      <PromoPageFooter />
    </ContentWrapper>
  </ContentContainer>
</template>
