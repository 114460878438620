<template>
  <svg
    viewBox="0 0 135 130"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="#F79E00"
  >
    <g transform="matrix(1,0,0,1,-634.728,-382.568)">
      <path
        d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z"
        style="box-shadow: 2px 2px 3px #000000; background-image: linear-gradient(180deg, #F59103 0%, #F79E00 100%);"
      />
    </g>
  </svg>
</template>
