<template>
  <div
    class="
      fixed shrink-0 right-0 top-5 md:top-[86px] lg:top-[107px] z-[200]
      text-zinc-100 cursor-pointer bg-green-950
      rounded-tl-[6.12px] rounded-bl-[6.12px] md:rounded-tl-[13.6px] md:rounded-bl-[13.6px]
      border-b border-green-600 border-width-[0.45px] md:border-width-[1px]
      duration-100 transition-[width] ease-in-out
    "
  >
    <slot />
  </div>
</template>
