<template>
  <p
    class="
      md:text-2xl font-extrabold
      !leading-[95%]
      tracking-primary md:tracking-primaryMd
    "
  >
    <slot />
  </p>
</template>
