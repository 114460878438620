<script lang="ts" setup>
import SubHeaderWrapper from '~/atoms/layouts/SubHeaderWrapper';
import useTheme from '~/composables/theme/useTheme';

const { contentClasses } = useTheme();
</script>

<template>
  <SubHeaderWrapper
    class="
      justify-center
      mt-4 md:mt-[34px]
    "
  >
    <p
      :class="[...contentClasses.description]"
      class="
        text-center text-zinc-100 leading-[1.2] max-w-[338px] md:max-w-[663px]
      "
    >
      Be among the <u>first</u> to experience
      <span class="lg:hidden">Junglebet,</span>
      the future of online casino and sportsbook!
    </p>
  </SubHeaderWrapper>
</template>
