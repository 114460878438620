<script lang="ts" setup>
import { AUTH } from '~/constants';
import FormField from '~/components/molecules/form/FormField.vue';
import JTextarea from '~/atoms/JTextarea.vue';
import JButton from '~/atoms/JButton.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { WORDLIST } from '~/constants/wordlist';
import { CLIENTSEED_MUTATION } from '~/graphql';
import { useMutation } from '@vue/apollo-composable';
import { notify } from '~/atoms';

const { me }: any = inject(AUTH);

const { modalVisible } = defineProps<{
  modalVisible: boolean;
}>();

const wordCount = (value: string) => {
  return value.trim().split(' ').length == 12;
};
const numberOfWords = 12;

const emits = defineEmits<{
  (e: 'close', payload: boolean): void;
}>();

const client_seed = computed(() => {
  return me.value?.clientSeeds?.find((el: any) => {
    return el.game_id === 13 && el.is_current === true;
  })?.client_seed;
});

const handleModalClose = () => {
  emits('close', false);
};

const generateMnemonics = () => {
  const wordList = WORDLIST;
  let mnemonic = '';
  for (let i = 0; i < numberOfWords; i++) {
    const index = Math.floor(Math.random() * wordList.length);
    mnemonic += wordList[index] + ' ';
  }
  form.clientSeed = mnemonic.trim();
};
const rules = {
  clientSeed: {
    required,
    wordCount,
  },
};

const form = reactive({
  clientSeed: '',
});

const v$ = useVuelidate(rules, form);

const inputClientSeed = async () => {
  await v$.value.clientSeed.$validate();
};

const { mutate, loading, onDone, onError } = useMutation(CLIENTSEED_MUTATION);

const onSubmit = async () => {
  if (!(await v$.value.$validate()) || loading.value) {
    return;
  }

  await mutate({
    gameId: 13,
    input: form.clientSeed,
  });
};

onDone(async ({ data }) => {
  loading.value = false;
  emits('close', false);
  notify.success({
    title: 'Client Seed updated',
    description: 'Your client seed has been updated!',
  });
});

onError((error) => {
  loading.value = false;
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Error saving Client Seed',
    description:
      parsedError.message || 'Something went wrong. Please try again!',
  });
});

watch(client_seed, (newValue) => {
  form.clientSeed = newValue;
});

onMounted(() => {
  form.clientSeed = client_seed.value;
});
</script>
<template>
  <JbSimpleModal
    :is-open="modalVisible"
    :no-header="false"
    class="w-full flex flex-col gap-3 items-start mt-3 text-xs md:text-sm"
    class-name="w-[480px] h-full bg-chip my-8 md:my-0 px-[26px] py-[26px]"
    @close:simple-modal="handleModalClose"
  >
    <template #header>
      <div class="font-bold flex gap-x-[10px] md:gap-x-[25px]">
        <p>Define my client seed</p>
      </div>
    </template>
    <div class="w-full flex flex-col gap-3 items-start mt-3 text-xs md:text-sm">
      <p class="text-xs text-left">
        You can define and save the spin client seeds to help you verify the
        fairness, but keep in mind that we rotate the server seed before every
        spin
      </p>
    </div>
    <div class="my-2 w-full text-left">
      <span>My Client Seed</span>
      <FormField
        :class="
          v$.clientSeed.$error
            ? cn('!border-red-600')
            : v$.clientSeed.$dirty
              ? cn('!border-highlights')
              : ''
        "
        class="w-full hover:border-highlights"
      >
        <JTextarea
          v-model="form.clientSeed"
          placeholder="Client seed"
          @update:model-value="inputClientSeed"
        />
      </FormField>
      <JbInputMessage v-if="v$.clientSeed.$error">
        Use a {{ numberOfWords }} word list as seed or click in "Generate random
        seed"
      </JbInputMessage>
    </div>
    <div class="flex">
      <div class="my-2 w-6/12 text-left px-1">
        <JButton
          class="w-full"
          size="sm"
          variant="alternative"
          @click="generateMnemonics"
        >
          Generate Random Seed
        </JButton>
      </div>
      <div class="my-2 w-6/12 text-left px-1">
        <JButton
          :loading="loading"
          class="w-full"
          size="sm"
          variant="primary"
          @click="onSubmit"
        >
          Save client seed
        </JButton>
      </div>
    </div>
  </JbSimpleModal>
</template>
