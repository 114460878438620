<script lang="ts" setup>
import { useMutation } from '@vue/apollo-composable';
import { cn, parseGraphqlError } from '~/utils';
import JButton from '~/atoms/JButton.vue';
import { AUTH, FINISH_REGISTRATION, LOGIN, NEEDS_MERGE, PROMO_WHEEL_SPIN } from '~/constants';
import { LOGIN_SOCIAL_MUTATION } from '~/graphql';
import { notify } from '~/atoms';

const {
  username,
  password,
  loading: authLoading,
  openAuthModal,
  turnstileToken,
  hideTurnstile,
  login,
}: any = inject(AUTH);

const {
  email,
  showFreeSpin,
  showRegisterAddress,
  agreeTos,
  sequence,
  verificationCode,
  isAvailable,
  isVerified,
  degreeVal,
}: any = inject(PROMO_WHEEL_SPIN);

const { mutate, loading, onDone, onError } = useMutation(LOGIN_SOCIAL_MUTATION);

const { onLogin } = useApollo();
const route = useRoute();
const router = useRouter();
const shouldCheckSocial = ref<boolean>(false);
const loadingCheck = computed(() => authLoading.value);

const resetFields = () => {
  degreeVal.value = 25;
  sequence.value = 0;
  isAvailable.value = false;
  isVerified.value = false;
  showRegisterAddress.value = false;
  email.value = '';
  username.value = '';
  password.value = '';
  verificationCode.value = '';
  if (!hideTurnstile.value) {
    turnstileToken.value = false;
  }
};

const handleRegister = () => {
  agreeTos.value = true;
  showFreeSpin.value = false;
  resetFields();
};

onDone(async ({ data }) => {
  if (data.loginSocial.socialResponse.needsMerge) {
    router.push({
      query: { ...route.query, type: NEEDS_MERGE, modal: 'auth' },
    });
    username.value = data.loginSocial.socialResponse.email;
  } else if (data.loginSocial.user.incomplete_profile) {
    if (
      data.loginSocial.socialResponse.email === '' ||
      data.loginSocial.user.email === null
    ) {
      router.replace({
        query: { ...route.query, type: LOGIN, modal: 'auth' },
      });
    } else {
      router.replace({
        query: { ...route.query, type: FINISH_REGISTRATION, modal: 'auth' },
      });
      username.value = data.loginSocial.user.email;
    }
  } else {
    await onLogin(data.loginSocial.token);
    await login();
    notify.success({
      title: 'Welcome to Junglebet!',
      description: `Enjoy Free Spin “${data.loginSocial.user.username}”!`,
    });
    showFreeSpin.value = true;

    navigateTo('/');
  }
});

onError(async (error) => {
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Authentication',
    description:
      parsedError.message || 'Something went wrong. Please try again!',
  });

  resetFields();

  navigateTo('/');
});

onMounted(() => {
  if (route.query.logid) {
    shouldCheckSocial.value = true;
  }
});

watch(
  loadingCheck,
  (value) => {
    if (!value && shouldCheckSocial.value) {
      mutate({
        hash: route.query.logid,
      });
    }
  },
  { immediate: loadingCheck.value },
);
</script>

<template>
  <div class="flex items-center pr-10 md:pr-0 gap-2">
    <JButton
      :class="
        cn([
          'py-[6px] px-[12px] sm:px-[10px] sm:py-[10px]',
          'sm:w-[92px] sm:h-[40px]',
          'text-paragraph hover:text-zinc-100',
        ])
      "
      :loading="loading"
      size="sm"
      variant="alternative"
      @click="openAuthModal"
    >
      Log in
    </JButton>
    <JButton
      v-if="showFreeSpin"
      :class="
        cn([
          'py-[6px] px-[12px] sm:px-[10px] sm:py-[10px]',
          'sm:h-[40px]',
          'text-white',
        ])
      "
      :loading="loading"
      size="sm"
      variant="primary"
      @click="handleRegister"
    >
      Register Now
    </JButton>
  </div>
</template>
