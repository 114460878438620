<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { checkLetters, checkNumberAndSymbol } from '~/utils';
import { ERRORS } from '~/constants';
import JbProgressBar from '~/atoms/JbProgressBar.vue';

const props = defineProps<{
  password: string;
}>();

const emits = defineEmits<{
  (e: 'passed', payload: boolean): void;
}>();

const form = reactive({
  password: props.password,
});

const rules = {
  password: {
    required,
    minLength: minLength(8),
    checkNumberAndSymbol,
    checkLetters,
  },
};

const v$ = useVuelidate(rules, form);

const descriptions = computed(() => [
  {
    color: 'bg-red-600',
    border: 'border-red-600',
    textColor: 'text-red-600',
    label: 'Is that even a password? Keep trying.',
  },
  {
    color: 'bg-red-300',
    border: 'border-red-300',
    textColor: 'text-red-300',
    label: ERRORS.PASSWORD_MIN_LENGTH,
  },
  {
    color: 'bg-yellow-400',
    border: 'border-yellow-400',
    textColor: 'text-red-400',
    label: ERRORS.PASSWORD_LETTERS,
  },
  {
    color: 'bg-green-200',
    border: 'border-green-200',
    textColor: 'text-green-200',
    label: ERRORS.PASSWORD_SYMBOL_NUMBER,
  },
  {
    color: 'bg-green-600',
    border: 'border-green-600',
    textColor: 'text-green-600',
    label: 'Password is secure!',
  },
]);

const score = computed(() => {
  const hasValue = props.password && props.password.length > 0;

  if (!hasValue) {
    return 0;
  }

  if (!v$.value.password.$error) {
    return 5;
  }

  if (v$.value.password.required.$invalid) {
    return 1;
  }

  if (v$.value.password.minLength.$invalid) {
    return 2;
  }

  if (
    v$.value.password.checkLetters.$invalid
  ) {
    return 3;
  }

  if (
    v$.value.password.checkNumberAndSymbol.$invalid
  ) {
    return 4;
  }

  return 5;
});

const description = computed(() =>
  props.password && props.password.length > 0
    ? descriptions.value[score.value - 1]
    : {
      color: 'bg-red-600',
      border: 'border-red-600',
      textColor: 'text-red-600',
      label: ERRORS.PASSWORD_REQUIRED,
    },
);

const isPasswordStrong = computed(() => score.value >= 4);

watch(isPasswordStrong, (value) => {
  value ? emits('passed', true) : emits('passed', false);
});

watch(
  () => props.password,
  async (value, oldValue) => {
    if (value !== oldValue) {
      form.password = value;
      await v$.value.password.$validate();
    }
  }, {
    immediate: true,
  });
</script>

<template>
  <div class="select-none w-full">
    <JbProgressBar
      :border="description.border"
      :color="description.color"
      :max="descriptions.length"
      :val="score"
    />

    <p
      :class="description.textColor"
      class="absolute mt-1 text-sm"
    >
      {{ description.label }}
    </p>
  </div>
</template>
