export default function() {
  const mainLogo: string[] = [
    'w-[230px]',
    'h-[48px]',
    'md:w-[312px]',
    'md:h-[64px]',
    'lg:w-[390px]',
    'lg:h-[80px]',
  ];

  return {
    mainLogo,
  };
}
