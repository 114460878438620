<script lang="ts" setup>
import { FREE_SPIN_OPTIONS } from '~/constants/wheelSpinConfigs';
import SocialShareLink from '~/components/pages/chip-promo/organisms/SocialShareLink.vue';
import { AUTH, PROMO_WHEEL_SPIN } from '~/constants';

const { modalVisible } = defineProps<{
  modalVisible: boolean;
}>();

const emits = defineEmits<{
  (e: 'close:wonModal', payload: boolean): void;
}>();

const { me, isVisibleProfile }: any = inject(AUTH);
const {
  spinResult,
  showRegisterAddress,
  showFreeSpin,
}: any = inject(PROMO_WHEEL_SPIN);

const sponsorCode = computed(() => me.value?.sponsorCodes[0]?.code || null);
const handleModalClose = () => {
  emits('close:wonModal', false);
};

const openRegisterForm = () => {
  handleModalClose();
  showRegisterAddress.value = true;
  showFreeSpin.value = false;
};

const openProfileForm = () => {
  handleModalClose();
  isVisibleProfile.value = true;
};
</script>

<template>
  <JbSimpleModal
    :is-open="modalVisible"
    class-name="
      w-full md:w-[326px] bg-won-gradient mt-12 md:mt-0 pt-[15px] pb-[15px] h-auto
    "
    @close:simple-modal="handleModalClose"
  >
    <div
      class="
          absolute top-0 left-0 z-[1]
          w-full h-full
          bg-won bg-no-repeat bg-contain bg-top bg-opacity-40
        "
    />

    <div
      class="
        px-5 -mt-2
        z-[5] w-full h-full
        flex flex-col gap-6 items-center
      "
    >
      <p
        class="
            text-center text-[22.743px] font-extrabold
            leading-[95%] tracking-[1.365px] uppercase
          "
      >
        CONGRATS<br>YOU’VE WON
      </p>

      <p
        class="
            text-center text-[82.74px] font-extrabold
            leading-[95%] tracking-[4.964px]
          "
      >
        {{ FREE_SPIN_OPTIONS[spinResult].label }}
      </p>

      <div class="w-full flex flex-col gap-[11px] items-center">
        <p
          class="
            text-center text-[15.162px] font-bold
            leading-[130%] text-paragraph w-[277px]
          "
        >
          Your winnings were credited to your account
          and will be available to play with on launch day!
        </p>

        <p
          v-if="!(me?.address)"
          class="
            text-center text-[15.162px] font-medium
            leading-[130%] text-paragraph w-[277px]
          "
        >
          Want another FREE SPIN? Register your address for
          another chance!
        </p>
        <div
          v-else-if="!(sponsorCode)"
          class="
            text-center text-[15.162px] font-medium
            leading-[130%] text-paragraph w-[277px] mt-3
          "
        >
          <p class="text-[#ECF0EC] font-bold">
            EARLY BIRD REFERRAL PROGRAM
          </p>

          <p>
            Share your referral link with friends and receive <b>15% commission</b> on ALL your friends future H.E.
            wagering - for life.<br><br>
            Additionally receive <span class="font-bold">1 FREE SPIN</span> <u>per</u> registered user!
          </p>
        </div>

        <JbButton
          v-if="!(me?.address)"
          :stretch="false"
          class="bg-primary-button scale-100 active:scale-[0.98] !text-base"
          size="md"
          @click="openRegisterForm"
        >
          Register My Address
        </JbButton>

        <JbButton
          v-else-if="!(sponsorCode)"
          :stretch="false"
          class="bg-spinner-button scale-100 active:scale-[0.98] !text-base !text-green-950"
          size="md"
          @click="openProfileForm"
        >
          Get My Referral Link
        </JbButton>

        <p
          class="
            text-center text-[15.162px]
            text-paragraph w-[277px]
          "
        >
          Spread the word about Junglebet.com, be part of our early
          <a
            class="font-bold underline"
            href="javascript: void(0);"
            target="_blank"
            @click="openProfileForm"
          >AFFILIATE</a> program with increased commission.
          <a
            class="font-bold underline"
            href="/terms"
            target="_blank"
            @click.stop
          >T&C’s</a> Apply.
        </p>

        <SocialShareLink />

        <p
          class="
            text-center text-xs
            text-paragraph w-[277px]
          "
        >
          <a
            class="font-semibold underline"
            href="/"
          >Login</a> to see your total credits.
        </p>

        <p
          class="
            text-center text-xs
            text-paragraph
          "
        >
          <a
            class="font-bold underline"
            href="/terms"
            target="_blank"
            @click.stop
          >T&C’s</a> Apply. Please note that referrals from accounts with the same IP address will not be recognised. We
                     also use various other fingerprinting techniques. This isn't our first rodeo.
        </p>
      </div>
    </div>
  </JbSimpleModal>
</template>
