<template>
  <svg
    fill="none"
    viewBox="0 0 86 89"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style="mix-blend-mode:multiply">
      <path
        d="M64.3379 67.414C76.1165 55.8185 76.1165 37.0184 64.3379 25.4229C52.5593 13.8274 33.4624 13.8274 21.6838 25.4229C9.90522 37.0184 9.90523 55.8185 21.6838 67.414C33.4624 79.0095 52.5593 79.0095 64.3379 67.414Z"
        fill="#22974A30"
      />
    </g>
    <path
      d="M64.3379 63.0793C76.1165 51.4838 76.1165 32.6837 64.3379 21.0882C52.5593 9.49268 33.4624 9.49268 21.6838 21.0882C9.90522 32.6837 9.90522 51.4838 21.6838 63.0793C33.4624 74.6748 52.5593 74.6748 64.3379 63.0793Z"
      fill="url(#paint0_linear_1_21324)"
    />
    <path
      d="M60.8333 59.6293C70.6764 49.9391 70.6764 34.2283 60.8333 24.5382C50.9902 14.8481 35.0313 14.8481 25.1881 24.5382C15.345 34.2283 15.345 49.9391 25.1881 59.6293C35.0313 69.3194 50.9902 69.3194 60.8333 59.6293Z"
      fill="#F1F1F1"
    />
    <g style="mix-blend-mode:multiply">
      <path
        d="M57.9753 60.0403C66.2399 51.9042 66.2399 38.713 57.9753 30.5769C49.7107 22.4408 36.3112 22.4408 28.0467 30.5769C19.7821 38.713 19.7821 51.9042 28.0467 60.0403C36.3112 68.1764 49.7107 68.1764 57.9753 60.0403Z"
        fill="#ECF0EC"
      />
    </g>
    <path
      d="M57.9753 56.8154C66.2399 48.6793 66.2399 35.4881 57.9753 27.352C49.7107 19.2159 36.3112 19.2159 28.0467 27.352C19.7821 35.4881 19.7821 48.6793 28.0467 56.8154C36.3112 64.9515 49.7107 64.9515 57.9753 56.8154Z"
      fill="#22974A"
    />
    <path
      d="M53.8411 46.1515C56.1232 40.2557 53.1182 33.6549 47.1293 31.4083C41.1404 29.1617 34.4354 32.12 32.1534 38.0159C29.8713 43.9117 32.8763 50.5124 38.8652 52.759C44.8541 55.0056 51.559 52.0473 53.8411 46.1515Z"
      fill="#F1F1F1"
    />
    <g style="mix-blend-mode:multiply">
      <path
        d="M40.9824 34.0585C47.294 32.963 53.3127 37.1119 54.4256 43.3253C54.4495 43.4594 54.4573 43.5922 54.4763 43.7262C54.6497 42.5518 54.6464 41.3325 54.4256 40.1C53.3127 33.8865 47.294 29.7377 40.9824 30.8331C34.8071 31.9051 30.6532 37.6015 31.5185 43.6666C32.2186 38.9206 35.9228 34.9368 40.9824 34.0585Z"
        fill="#ECF0EC"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_21324"
        gradientUnits="userSpaceOnUse"
        x1="21.6838"
        x2="63.6698"
        y1="21.0882"
        y2="63.7371"
      >
        <stop
          offset="0"
          stop-color="#28A553"
        />
        <stop
          offset="1"
          stop-color="#107031"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
