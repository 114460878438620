<script setup lang="ts">
type Shape = 'circle' | 'rectangle' | 'none';

const props = withDefaults(
  defineProps<{
    shape?: Shape;
    color?: string;
    loaderGradient?: string;
    backgroundColor?: string;
  }>(),
  {
    shape: 'rectangle',
    color: '#ACCCAC',
    loaderGradient: '',
    backgroundColor: '#021902',
  },
);

const shapeToClassMap = {
  circle: 'rounded-full',
  rectangle: 'rounded-lg',
  none: '',
};
const shapeClass = computed(() => shapeToClassMap[props.shape]);

const loaderStyle = computed(() => {
  if (props.loaderGradient) {
    return { backgroundImage: props.loaderGradient };
  }
  // generating sensible gradient by color
  const rgb = isHexColor(props.color)
    ? hexToRgb(props.color)
    : hexToRgb('#ACCCAC');

  return {
    backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
  };
});
const isHexColor = (hexColor: string) => {
  return /^#[0-9A-Fa-f]{6}$/i.test(hexColor);
};

const hexToRgb = (hex: string): string => {
  const matches = hex.match(/\w\w/g);

  return matches!.map(x => parseInt(x, 16)).join(',');
};
</script>

<template>
  <div
    class="relative overflow-hidden"
    :class="[shapeClass]"
    :style="{
      backgroundColor
    }"
  >
    <div
      class="jb-shimmer absolute top-0 right-0 bottom-0 left-0"
      :style="loaderStyle"
    />
    <slot />
  </div>
</template>

<style lang="css">
.jb-shimmer {
  transform: translateX(-100%);
  animation: shimmer 1.4s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
