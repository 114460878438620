<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { useLazyQuery, useMutation } from '@vue/apollo-composable';
import { MapPinIcon } from '@heroicons/vue/20/solid';
import InputWrapper from '~/components/molecules/common/InputWrapper.vue';
import { AUTH, ERRORS, PROMO_WHEEL_SPIN } from '~/constants';
import { SMALL_CIRCLE_WARNING_ICON } from '~/icons/icons';
import { parseGraphqlError } from '~/utils';
import { GEOCODE_ADDRESS_QUERY, SAVE_ADDRESS_MUTATION } from '~/graphql';
import { notify } from '~/atoms';
import type { Address } from '~/types';

const loadingSaving = ref<boolean>(false);
const showPopup = ref<boolean>(false);
const firstLoad = ref<boolean>(false);
const sendingRequest = ref<boolean>(true);
const searchedAddresses = ref<Address[] | null>();
const {
  showFreeSpin,
  showRegisterAddress,
}: any = inject(PROMO_WHEEL_SPIN);

const { isLoggedIn, refetch }: any = inject(AUTH);

const form = reactive({
  address: '',
  formatted_address: '',
  latitude: 0,
  longitude: 0,
  number: '',
  street: '',
  postalcode: '',
  subLocality: '',
  country_id: 0,
  state_id: 0,
  city_id: 0,
});

const rules = {
  address: {
    required,
    minLength: minLength(3),
  },
};

const v$ = useVuelidate(rules, form);
const {
  load: addressLoad,
  result: addressResult,
  loading: addressLoading,
  refetch: addressReFetch,
  onResult: onAddressResult,
  onError: onAddressError,
} = useLazyQuery<{
  geocodeAddress: Address[];
}>(GEOCODE_ADDRESS_QUERY, {
  input: form.address || ERRORS.ADDRESS_INPUT,
}, { fetchPolicy: 'cache-and-network' });

const {
  mutate,
  loading: loadingForAddress,
  onDone,
  onError,
} = useMutation(SAVE_ADDRESS_MUTATION);

const resetFormValues = () => {
  showPopup.value = false;
  form.formatted_address = '';
  form.longitude = 0;
};

const incorrectAddress = (val: Address) => {
  return val.formatted_address === '' || !val.formatted_address || !val.longitude || !val.latitude ||
    val.postalcode === '' || !val.postalcode || !val.country_id || !val.state_id || !val.city_id;
};

const debounceSearch = useDebounceFn(() => {
  if (!sendingRequest.value || !isLoggedIn.value) {
    return;
  }

  if (form.address === '' || form.address.length < 3) {
    resetFormValues();
    return;
  }

  showPopup.value = false;

  if (firstLoad.value) {
    addressReFetch({
      input: form.address,
    });
  } else {
    firstLoad.value = true;
    addressLoad();
  }
}, 300);

onAddressResult(({ data }) => {
  searchedAddresses.value = data?.geocodeAddress?.filter(el => !(incorrectAddress(el)));
  showPopup.value = true;
});

onAddressError((error) => {
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Search Address',
    description: parsedError.message || 'Something went wrong. Please try again!',
  });
});

const isInvalidAddress = computed(() => (
  form.formatted_address === '' || !form.formatted_address || !form.longitude || !form.latitude ||
  form.postalcode === '' || !form.postalcode || !form.country_id || !form.state_id || !form.city_id
));

const clickAddressItem = (item: Address) => {
  sendingRequest.value = false;

  form.address = item.formatted_address;
  form.formatted_address = item.formatted_address;
  form.latitude = item.latitude;
  form.longitude = item.longitude;
  form.number = item.number;
  form.street = item.street;
  form.postalcode = item.postalcode;
  form.subLocality = item.subLocality;
  form.country_id = typeof item.country_id === 'string' ? parseInt(item.country_id) : item.country_id;
  form.state_id = typeof item.state_id === 'string' ? parseInt(item.state_id) : item.state_id;
  form.city_id = typeof item.city_id === 'string' ? parseInt(item.city_id) : item.city_id;
  showPopup.value = false;
};

const handleSubmit = async () => {
  if (!(await v$.value.$validate()) || loadingSaving.value || loadingForAddress.value || isInvalidAddress.value) {
    return;
  }

  loadingSaving.value = true;
  mutate({
    formatted_address: form.formatted_address,
    latitude: form.latitude,
    longitude: form.longitude,
    number: form.number,
    street: form.street,
    postalcode: form.postalcode,
    subLocality: form.subLocality,
    country_id: form.country_id,
    state_id: form.state_id,
    city_id: form.city_id,
  });
};

onDone(async () => {
  await refetch();
  loadingSaving.value = false;
  showRegisterAddress.value = false;
  showFreeSpin.value = true;
});

onError((error) => {
  loadingSaving.value = false;
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Register Address',
    description: parsedError.message || 'Something went wrong. Please try again!',
  });
});

const inputAddress = async (val: string) => {
  showPopup.value = false;
  resetFormValues();
  form.address = val;
  await v$.value.address.$validate();
  if (val && val.length >= 2 && val !== form.formatted_address) {
    sendingRequest.value = true;
    debounceSearch();
  }
};
</script>

<template>
  <form @submit.prevent="handleSubmit()">
    <div
      v-if="!showFreeSpin && showRegisterAddress"
      class="w-full flex flex-col gap-2"
    >
      <InputWrapper
        :autofocus="true"
        :class="v$.address.$error ? '!border-red-600' : (v$.address.$dirty ? '!border-highlights' : '')"
        :loading="addressLoading"
        :model-value="form.address"
        class="border-paragraph w-full hover:border-highlights"
        placeholder="Enter address"
        @keypress="value => inputAddress(value)"
        @update:model-value="value => form.address = value"
      >
        <JbButton
          :disabled="!form.address || v$.address.$error || isInvalidAddress"
          :loading="loadingForAddress || loadingSaving"
          class="
            bg-primary-button scale-100 active:scale-[0.98] text-sm
            w-[100px] gap-[7.359px] px-[17.662px] py-[11.775px]
            disabled:text-[#1D391D]
          "
          size="md"
          @click="handleSubmit"
        >
          Register
        </JbButton>
      </InputWrapper>

      <div class="h-[28.78px] w-full">
        <JbInputMessage v-if="v$.address.$error">
          <svg
            class="w-3 h-3 text-red-600"
          >
            <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
          </svg>
          {{
            v$.address.minLength.$invalid
              ? ERRORS.TIP_USERNAME_MIN_LENGTH
              : (v$.address.required.$invalid
                ? ERRORS.WHITELIST_ADDRESS_REQUIRED
                : ERRORS.INVALID_WHITELIST_ADDRESS)
          }}
        </JbInputMessage>

        <template v-if="!addressLoading && !loadingForAddress && showPopup">
          <div
            v-if="searchedAddresses && searchedAddresses.length"
            class="
            absolute top-[54px] left-0 right-0
            rounded-b-primary bg-chip z-[300]
            border-[1px] border-solid border-t-0 border-l-highlights border-b-highlights border-r-highlights
          "
          >
            <div
              v-for="(addressEl, a) in searchedAddresses"
              :key="a"
              class="
                mb-1 flex items-center gap-x-2 p-2
                cursor-pointer h-[54px] text-base text-zinc-100
                hover:bg-highlights
              "
              @click="clickAddressItem(addressEl)"
            >
              <MapPinIcon
                class="w-4 h-4 min-w-4 min-h-4"
              />
              <p>
                {{ addressEl.formatted_address }}
              </p>
            </div>
          </div>
          <div
            v-else
            class="
              absolute top-[54px] left-0 right-0
              bg-chip text-zinc-100 flex items-center gap-x-2
              p-2 cursor-pointer h-[54px] text-base
              border-[1px] border-solid border-t-0 border-l-highlights border-b-highlights border-r-highlights
              rounded-b-primary
            "
            @click="showPopup = false"
          >
            <MapPinIcon
              class="w-4 h-4 min-w-4 min-h-4 text-zinc-100"
            />
            <p>
              Hmm...looks like you don't live on Earth? Please enter a valid address (on Earth).
            </p>
          </div>
        </template>
      </div>
    </div>
  </form>
</template>
