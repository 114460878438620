<script lang="ts" setup>
import dayjs from 'dayjs';
import copy from 'copy-to-clipboard';
import JInput from '~/atoms/JInput.vue';
import FormField from '~/components/molecules/form/FormField.vue';
import { useTimeoutFn } from '@vueuse/core';
import { useLazyQuery } from '@vue/apollo-composable';
import { AUTH } from '~/constants';
import { cn, parseGraphqlError } from '~/utils';
import {
  BAR_CHART_ICON,
  CIRCLE_CHECK_ICON,
  CLIPBOARD_ICON,
  LINK_ICON,
  SHARE_SOCIAL_ICON,
  WALLET_ICON,
} from '~/icons/icons';
import { APPLY_PROMO_CODE, GET_SPONSOR_CODE_QUERY } from '~/graphql';
import { notify } from '~/atoms';
import SocialShareLink from '~/components/pages/chip-promo/organisms/SocialShareLink.vue';

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const { me, refetch }: any = inject(AUTH);

const wallets = computed(() =>
  me.value?.wallets?.reduce((accumulator: number, currentValue: any) => {
    return accumulator + (currentValue.available_balance || 0);
  }, 0),
);

const showDetails = computed<boolean>(
  () => !!me.value?.sponsorCodes[0]?.code || false,
);
const url = useRequestURL();
const siteHost = computed(() => `${url.protocol}//${url.host}`);
const readyGetCode = ref<boolean>(false);
const copiedCode = ref<boolean>(false);
const sponsorCode = ref<string>('');

const {
  load: getSponsorCode,
  loading: sponsorLoading,
  result,
  onError: onSponsorCodeError,
  onResult: onSponsorCodeResult,
} = useLazyQuery(GET_SPONSOR_CODE_QUERY, {}, { fetchPolicy: 'network-only' });

const promoCode = ref();

const handleCodeInput = () => {
  promoCode.value = promoCode.value.toUpperCase();
};

const {
  mutate,
  loading: promocodeLoading,
  onDone,
  onError,
} = useMutation(APPLY_PROMO_CODE);

const onSubmit = async () => {
  mutate({
    code: promoCode.value,
  });
};

onDone((response) => {
  notify.success({
    title: 'Success!',
    description:
      'Promo code sucessfully applyed, you have received ' +
      response.data?.applyPromoCode?.value +
      ' free spins',
  });
});

onError((error) => {
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Error!',
    description:
      parsedError.message || 'Something went wrong. Please try again!',
  });
});

onSponsorCodeError((error) => {
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed!',
    description:
      parsedError.message || 'Something went wrong. Please try again!',
  });
});

onSponsorCodeResult(({ data }) => {
  sponsorCode.value = data?.createOrGetSponsorCode?.code || null;
  refetch();
});

const handleCreateSponsorCode = () => {
  getSponsorCode();
};

const handleCopyClipboard = () => {
  const copyLink: string = `${siteHost.value}/promo/${sponsorCode.value}`;
  navigator.clipboard.writeText(copyLink);
  copy(copyLink);
  copiedCode.value = true;

  useTimeoutFn(() => {
    copiedCode.value = false;
  }, 2000);
};

const onFreeSpin = () => {
  emits('close');
};

const openDownloadKitLink = () => {
  window.open(
    'https://www.dropbox.com/scl/fo/s66mtgtyupwbtb87fiaxp/h?rlkey=x970dnp39ztqo5y1hd0wrd3s0&dl=0',
    '_new',
  );
};

onMounted(() => {
  readyGetCode.value = true;

  if (showDetails.value) {
    sponsorCode.value = me.value?.sponsorCodes[0]?.code || null;
  }
});
</script>

<template>
  <div class="w-full text-left text-white">
    <div class="w-full">
      <div class="w-full text-paragraph text-xxs font-bold leading-normal">
        Joined {{ dayjs(me.created_at).format('D MMMM YYYY') }}
      </div>

      <div class="w-full text-[25px] font-bold">
        Hello there, {{ me?.username }}.
      </div>

      <JbLine />
    </div>

    <div class="w-full">
      <div class="w-full flex items-center gap-x-2 md:gap-x-[7px]">
        <svg class="h-[15px] w-[14px] text-paragraph">
          <use :xlink:href="WALLET_ICON" />
        </svg>
        <span class="font-bold text-[18px]">My Wallet</span>
      </div>
      <div class="w-full">
        <div class="w-full text-sm font-bold">Available Credit</div>
        <div
          class="text-sm w-full rounded-md px-[21px] bg-green-950 min-h-6 h-12 mt-2 flex items-center leading-none"
        >
          {{
            Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(wallets)
          }}
        </div>

        <JbLine />
      </div>
    </div>

    <!-- promo codes -->
    <div class="w-full">
      <div class="w-full flex items-center gap-x-2 md:gap-x-[7px]">
        <svg class="h-[15px] w-[15px] text-paragraph">
          <use :xlink:href="LINK_ICON" />
        </svg>
        <span class="font-bold text-[18px]"> Apply promo code </span>
      </div>

      <p class="w-full text-paragraph text-sm leading-none mt-[7px]">
        You can get extra <span class="font-bold">FREE SPINS</span> by claiming
        a Promo Code
      </p>

      <FormField class="w-full hover:border-highlights mt-2">
        <JInput
          v-model="promoCode"
          placeholder="Insert promo code here"
          @update:model-value="handleCodeInput"
        >
        </JInput>
      </FormField>

      <JbButton
        :class="
          cn([
            'scale-100 active:scale-[0.995] w-full md:w-[158px] mt-4 h-11 max-h-11',
            'bg-spinner-button text-green-950 text-sm',
          ])
        "
        :loading="promocodeLoading"
        size="md"
        @click="onSubmit"
      >
        Apply promo code
      </JbButton>

      <JbLine />
    </div>
    <!-- promo codes -->

    <div class="w-full">
      <div class="w-full flex items-center gap-x-2 md:gap-x-[7px]">
        <svg class="h-[15px] w-[15px] text-paragraph">
          <use :xlink:href="LINK_ICON" />
        </svg>
        <span class="font-bold text-[18px]"> Refer & Earn </span>
      </div>

      <p class="w-full text-paragraph text-sm leading-none mt-[7px]">
        Share your unique link with friends to earn commission along
        <span class="font-bold">1 FREE SPIN</span>
        for each new user - Enjoy 15% commission of their H.E wagering for life!
      </p>

      <JbButton
        v-if="!showDetails && readyGetCode"
        :class="
          cn([
            'scale-100 active:scale-[0.995] w-full md:w-[158px] mt-4 h-11 max-h-11',
            'bg-spinner-button text-green-950 text-sm',
          ])
        "
        :loading="sponsorLoading"
        size="md"
        @click="handleCreateSponsorCode"
      >
        Create Link
      </JbButton>

      <div v-if="showDetails" class="w-full mt-3">
        <div class="w-full">
          <div class="w-full text-sm font-bold">Affiliate Link</div>
          <div
            class="text-sm w-full rounded-md px-[21px] bg-green-950 min-h-6 h-12 mt-2 flex items-center justify-between gap-x-2 leading-none border border-solid border-paragraph"
          >
            <div class="overflow-hidden text-nowrap">
              {{ siteHost }}/promo/{{ sponsorCode }}
            </div>
            <JbButton
              :class="cn(['!py-0 !px-0 !h-5 !w-5 bg-none border-0'])"
              @click="handleCopyClipboard"
            >
              <svg v-if="copiedCode" class="h-5 w-5 text-primary">
                <use :xlink:href="CIRCLE_CHECK_ICON" />
              </svg>
              <svg v-else class="h-5 w-5 text-zinc-100">
                <use :xlink:href="CLIPBOARD_ICON" />
              </svg>
            </JbButton>
          </div>
        </div>

        <div
          class="w-full mt-3 text-sm text-paragraph flex items-center gap-[9px] flex-wrap"
        >
          <span class="w-full md:w-auto">or share</span>

          <SocialShareLink :show-np="false" />
        </div>

        <JbLine />

        <div class="w-full flex items-center gap-x-2 md:gap-x-[7px]">
          <svg class="w-[15px] h-4 text-paragraph">
            <use :xlink:href="BAR_CHART_ICON" />
          </svg>
          <span class="font-bold text-[18px]"> Affiliate Performance </span>
        </div>

        <div class="w-full grid grid-cols-3 gap-[9px] mt-2">
          <div
            class="w-full col-span-3 md:col-span-2 flex items-center gap-[9px]"
          >
            <div class="text-sm w-full">
              <div class="font-bold">Registered Users</div>
              <div
                class="rounded-md px-[21px] bg-green-950 min-h-6 h-12 mt-1 flex items-center leading-none"
              >
                {{ me?.total_affiliates }}
              </div>
            </div>
            <div class="text-sm w-full">
              <div class="font-bold">Free Spins</div>
              <div
                class="rounded-md px-[21px] bg-green-950 min-h-6 h-12 mt-1 flex items-center leading-none"
              >
                {{ me?.free_spins }}
              </div>
            </div>
          </div>
          <div class="text-sm w-full col-span-3 md:col-span-1">
            <JbButton
              :class="
                cn([
                  'scale-100 active:scale-[0.995] w-full !h-12 !max-h-12 mt-0 md:mt-[25px]',
                  'bg-spinner-button text-green-950 text-base !px-2',
                ])
              "
              :disabled="me?.free_spins <= 0"
              :stretch="true"
              size="md"
              @click="onFreeSpin"
            >
              FREE SPIN!
            </JbButton>
          </div>
        </div>

        <JbLine />

        <div class="w-full flex items-center gap-x-2 md:gap-x-[7px] -mt-2">
          <svg class="w-[18px] h-[18px] text-paragraph">
            <use :xlink:href="SHARE_SOCIAL_ICON" />
          </svg>
          <span class="font-bold text-[18px]"> Share on Social Media </span>
        </div>
        <p class="w-full mt-1 text-sm text-paragraph mb-2">
          Download our ready-to-use high resolution graphics to start marketing
          today!
        </p>
        <JbButton
          :class="
            cn([
              'bg-primary-button scale-100 active:scale-[0.98]',
              'w-full md:w-[182px] h-11 max-h-11',
              'gap-[7.359px]',
              'px-[17.662px] py-[11.775px]',
              'text-sm disabled:text-[#1D391D]',
            ])
          "
          size="md"
          @click="openDownloadKitLink"
        >
          Download Media Kit
        </JbButton>
      </div>

      <div v-else class="min-h-[5vh] md:min-h-[5vh] w-full" />

      <JbLine />
    </div>

    <div class="w-full text-paragraph text-xxs">
      T&C’s Apply. Please note that referrals from accounts with the same IP
      address will not be recognised. We also use other fingerprinting methods -
      This isn't our first rodeo.
    </div>
  </div>
</template>
